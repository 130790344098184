import React from "react";
import { useState } from "react";
import ReactQuill from "react-quill";
import {
  Avatar,
  Card,
  Button,
  Modal,
  DatePicker,
  Dropdown,
  Mentions,
  Tooltip,
  Upload,
} from "antd";
import {
  deleteData,
  fetchData,
  postData,
  putData,
  postFormData,
} from "../../../endpoints";
import {
  ExclamationCircleFilled,
  FlagFilled,
  PaperClipOutlined,
  PlusOutlined,
  EditOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import "./style.scss";
import access from "../../../assets/access";
import { useMobile } from "../../../hooks";
import SingleComment from "./singleComment";
import Cookies from "js-cookie";
import full from "../../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;
function CommentCard({
  comment,
  getComments,
  Users,
  filter,
  purchase,
  setDefult,
  openAddComment,
  noReply = false,
  patient = null,
}) {
  const [openReply, setOpenReply] = useState(false);
  var modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  var formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];
  const listOfColor = [
    "#ffa940",
    "#ffa39e",
    "#9254de",
    "#9e1068",
    "#597ef7",
    "#73d13d",
    "#5cdbd3",
    "#d3adf7",
    "#fa541c",
    "#d9f7be",
    "#fffb8f",
    "#d48806",
    "#5b8c00",
    "#cf1322",
    "#ffc069",
    "#eb2f96",
    "#d6e4ff",
    "#0958d9",
    "#389e0d",
    "#876800",
  ];
  const convertStringToHTML = (htmlString) => {
    let element = document.getElementById("myElement");
    if (element) element.innerHTML = htmlString;
  };

  function onChange(e) {
    convertStringToHTML(e);
    setText(e);
  }
  const isMobile = useMobile();
  const { Dragger } = Upload;
  const [loading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [text, setText] = useState(comment.text);
  const [modal, contextHolder] = Modal.useModal();
  const [addWatcherModal, setaddWatcherModal] = useState(false);
  const [addAssignerModal, setaddAssignerModal] = useState(false);
  const [watcherValue, setwatcherValue] = useState("");
  const [assignerValue, setAssignerValue] = useState("");
  const [temp, setTemp] = useState();
  const [addAttachment, setAddAttachment] = useState(false);
  const [getReply, setGetReply] = useState(false);

  const upload = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    setIsLoading(true);
    var x = comment.attachments.filter((a) => {
      return a.title == file.name;
    });
    if (x.length == 0) {
      const data = new FormData();
      data.append("file", file);
      data.append("commentId", comment.id);
      data.append("title", file.name);
      var res = await postFormData("admin/comment-attachment", data);
      if (res.status == 201) {
        Modal.success({
          content: "attachment added successfully!",
        });
        setAddAttachment(false);
        getComments(1, true, filter);
      } else {
        onError(Modal.error({ title: res }));
      }
    }
    setIsLoading(false);
  };

  const props = {
    name: "file",
    multiple: true,
    customRequest: upload,
    async onChange(info) {},
  };

  const confirm = async () => {
    Modal.confirm({
      title: "Are you sure you want to delete this comment?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        setIsLoading(true);
        var res = await deleteData(`admin/comment/${comment.id}`);
        if (res.status == 200) {
          let secondsToGo = 3;
          const instance = modal.success({
            title: "Comment deleted succesfully.",
          });
          const timer = setInterval(() => {
            secondsToGo -= 1;
            instance.update({});
          }, 1000);
          setTimeout(() => {
            clearInterval(timer);
            instance.destroy();
          }, secondsToGo * 1000);
          setIsLoading(false);
          console.log(filter);
          await getComments(1, true, filter);
        }
      },
      onCancel() {},
    });
  };

  const falgItems = [
    {
      label: "Urgent",
      key: "1",
      icon: <FlagFilled style={{ color: "#cf1322" }} />,
    },
    {
      label: "High",
      key: "2",
      icon: <FlagFilled style={{ color: "#ffec3d" }} />,
    },
    {
      label: "Normal",
      key: "3",
      icon: <FlagFilled style={{ color: "#fa8c16" }} />,
    },
    {
      label: "Low",
      key: "4",
      icon: <FlagFilled style={{ color: "#52c41a" }} />,
    },
  ];
  const onclickFlag = async (key) => {
    setIsLoading(true);
    var res = await putData("admin/comment/" + comment.id, {
      priority: parseInt(key.key),
    });
    if (res.status == 200) {
      Modal.success({ title: res.data.message });

      await getComments(1, true, filter);
    } else {
      Modal.error({ title: res });
    }
    setIsLoading(false);
  };
  var items = comment.watchers.map((w, index) => {
    var hex = listOfColor[w.user.id % 20];
    return {
      label: (
        <div
          onClick={async () => {
            Modal.confirm({
              title: "Are you sure you want to delete this watcher?",
              icon: <ExclamationCircleFilled />,
              okText: "Yes",
              okType: "danger",
              cancelText: "No",
              onOk: async () => {
                setIsLoading(true);
                var res = await deleteData(`admin/comment-watcher/${w.id}`);
                if (res.status == 200) {
                  let secondsToGo = 3;
                  const instance = modal.success({
                    title: "Viewer  deleted succesfully.",
                  });
                  const timer = setInterval(() => {
                    secondsToGo -= 1;
                    instance.update({});
                  }, 1000);
                  setTimeout(() => {
                    clearInterval(timer);
                    instance.destroy();
                  }, secondsToGo * 1000);
                  setIsLoading(false);
                  await getComments(1, true, filter);
                }
              },
              onCancel() {},
            });
          }}
        >
          {w.user.firstName
            ? w.user.firstName + " " + w.user.lastName
            : w.user.email}
        </div>
      ),
      key: w.id,
      icon: (
        <Avatar style={{ backgroundColor: `${hex}` }}>
          {w.user.firstName
            ? w.user.firstName[0] + w.user.lastName[0]
            : w.user.email.substr(0, 3)}
        </Avatar>
      ),
    };
    // }
  });
  items.push({
    label: (
      <div
        onClick={() => {
          setaddWatcherModal(true);
        }}
      >
        Add new viewer
      </div>
    ),
    key: -1,
    icon: (
      <Avatar>
        <PlusOutlined />
      </Avatar>
    ),
  });

  return (
    <>
      {contextHolder}
      <div
        dir="ltr"
        style={
          isMobile
            ? {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
              }
            : {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }
        }
      >
        {isMobile ? (
          <></>
        ) : (
          <Avatar
            style={
              isMobile
                ? {
                    backgroundColor: listOfColor[comment.reporterId % 20],
                    marginRight: "-5px",
                  }
                : {
                    backgroundColor: listOfColor[comment.reporterId % 20],
                    marginRight: "15px",
                  }
            }
          >
            {comment.reporter ? (
              comment.reporter.firstName[0] + comment.reporter.lastName[0]
            ) : (
              <></>
            )}
          </Avatar>
        )}

        <Card
          style={
            isMobile
              ? {
                  width: "100%",
                  marginTop: 8,
                  boxShadow: "-6px 0 0 0 rgba(231, 231, 231, 1)",
               
                  border:
                    "solid 0.5px, 0.5px, 0.5px, 5px rgba(231, 231, 231, 1)",
                }
              : {
                  width: "90%",
                  marginTop: 16,
               
                  boxShadow: "-6px 0 0 0 rgba(231, 231, 231, 1)",
                  border:
                    "solid 0.5px, 0.5px, 0.5px, 5px rgba(231, 231, 231, 1)",
                }
          }
          loading={loading}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="py-2" style={{ minWidth: "95%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  minWidth: "95%",
                  alignItems: "center",
                }}
              >
                <div className="col-12">
                  <div  className="d-flex flex-row-reverse gap-3 align-items-center col-12">
                 <div className="col-3 d-flex flex-row-reverse gap-3"> {!noReply && (
                      <div
                        onClick={() => {
                          console.log(comment);
                          setDefult({
                            parentId: comment.id,
                            purchaseId: comment.purchaseId,
                            purchaseStepId: comment.purchaseStepId,
                            patient: patient
                              ? patient
                              : {
                                  id: comment.patientId,
                                  firstName: comment.patientId,
                                  lastName: "",
                                },
                            patientId: comment.patientId,
                          });
                          openAddComment(true);
                        }}
                        style={
                          isMobile
                            ? {
                                padding: "2px",
                                borderRadius: "3px",
                                fontSize: "8px",
                                whiteSpace: "nowrap",
                                paddingRight: "4px",
                                backgroundColor: "rgba(76, 76, 76, 1)",
                                color: "white",
                                cursor: "pointer",
                              }
                            : {
                                padding: "2px",
                                borderRadius: "3px",
                                // position: "absolute",
                                color: "white",
                                backgroundColor: "rgba(76, 76, 76, 1)",
                                // right: "100px",
                                display: "flex",
                                alignItems: "center",
                                paddingRight: "4px",
                                cursor: "pointer",
                              }
                        }
                      >
                        <svg
                          style={{ marginRight: "5px" }}
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.25 5C14.4081 5 14.375 13.125 14.375 13.125C14.375 13.125 13.5831 9.375 6.25 9.375V12.5L0.625 7.0675L6.25 1.875V5Z"
                            fill="white"
                          />
                        </svg>

                        {"New Reply "}
                      </div>
                    )}
                     {comment.dueDate && (
                      <div
                        style={
                          isMobile
                            ? {
                                border: "0.5px solid rgba(158, 158, 158, 0.53)",
                                padding: "4px",
                                borderRadius: "5px",
                                fontSize: "8px",
                                whiteSpace: "nowrap",
                              }
                            : {
                                border: "0.5px solid rgba(158, 158, 158, 0.53)",
                                padding: "4px",
                                borderRadius: "5px",
                               
                              }
                        }
                      >
                        {new Date(comment.dueDate).toDateString().slice(3)}
                      </div>
                    )}</div>
                    <p
                      className={ "col-9 px-3"}
                      style={
                        isMobile
                          ? {
                              fontSize: "8px",
                              color: "rgba(158, 158, 158, 1)",
                            }
                          : {
                              // width: "72%",
                              fontSize: "12px",
                              color: "rgba(158, 158, 158, 1)",
                            }
                      }
                    >
                      {comment.purchaseId
                        ? purchase.filter((p) => {
                            return p.id == comment.purchaseId;
                          })[0]
                          ? purchase.filter((p) => {
                              return p.id == comment.purchaseId;
                            })[0].service.title
                          : ""
                        : ""}{" "}
                      {comment.purchaseStepId && comment.purchaseId ? ">" : ""}{" "}
                      {comment.purchaseId
                        ? comment.purchaseStepId ?? "purchase step Undefined"
                        : ""}
                    </p>
                   
                   
                  </div>
                  <div className="col-12 d-flex flex-row gap-1">
                    <div
                      className={
                        isMobile
                          ? "col-6 d-flex flex-row gap-3 align-items-center mt-1"
                          : "col-9 d-flex flex-row gap-3 align-items-center"
                      }
                    >
                      <p
                        style={
                          isMobile
                            ? {
                                fontSize: "10px",
                                whiteSpace: "nowrap",
                                fontWeight: "700",
                                marginTop: "5px",
                              }
                            : { fontSize: "14px", fontWeight: "700" }
                        }
                      >
                        {comment.reporter ? (
                          comment.reporter.firstName +
                          " " +
                          comment.reporter.lastName
                        ) : (
                          <></>
                        )}
                      </p>
                      <p
                        style={
                          isMobile
                            ? {
                                fontSize: "10px",
                                whiteSpace: "nowrap",
                                color: "rgba(158, 158, 158, 1)",
                              }
                            : {
                                fontSize: "12px",
                                color: "rgba(158, 158, 158, 1)",
                              }
                        }
                      >
                        {new Date(comment.createdAt).toLocaleString()}
                      </p>
                    </div>
                    {!isMobile && (
                      <div
                        style={{  marginTop: "10px" }}
                        className="col-3 py-1"
                        dir="rtl"
                      >
                        <Avatar.Group>
                          {comment.assignees.map((w) => {
                            var hex = listOfColor[w.user.id % 20];
                            return (
                              <Avatar
                                size="small"
                                style={{
                                  backgroundColor: `${hex}`,
                                  cursor: "pointer",
                                }}
                                onClick={async () => {
                                  Modal.confirm({
                                    title:
                                      "Are you sure you want to delete this assignee?",
                                    icon: <ExclamationCircleFilled />,
                                    okText: "Yes",
                                    okType: "danger",
                                    cancelText: "No",
                                    onOk: async () => {
                                      setIsLoading(true);
                                      var res = await deleteData(
                                        `admin/comment-assignee/${w.id}`
                                      );
                                      if (res.status == 200) {
                                        let secondsToGo = 3;
                                        const instance = modal.success({
                                          title:
                                            "assignee deleted succesfully.",
                                        });
                                        const timer = setInterval(() => {
                                          secondsToGo -= 1;
                                          instance.update({});
                                        }, 1000);
                                        setTimeout(() => {
                                          clearInterval(timer);
                                          instance.destroy();
                                        }, secondsToGo * 1000);
                                        setIsLoading(false);
                                        await getComments(1, true, filter);
                                      }
                                    },
                                    onCancel() {},
                                  });
                                }}
                              >
                                <Tooltip title={w.user.email}>
                                  {w.user.firstName != null
                                    ? w.user.firstName[0] + w.user.lastName[0]
                                    : w.user.email.substr(0, 3)}
                                </Tooltip>
                              </Avatar>
                            );
                          })}
                          <Avatar
                            size="small"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setaddAssignerModal(true);
                            }}
                          >
                            <Tooltip title={"Add new assignees"}>
                              <PlusOutlined />
                            </Tooltip>
                          </Avatar>
                        </Avatar.Group>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {isEdit ? (
                <div>
                  {" "}
                  <ReactQuill
                    value={text}
                    formats={formats}
                    modules={modules}
                    onChange={onChange}
                  />
                  <Button
                    size="small"
                    loading={loading}
                    type="primary"
                    style={{ marginTop: "5px", marginBottom: "15px" }}
                    onClick={async () => {
                      setIsLoading(true);
                      var res = await putData(`admin/comment/${comment.id}`, {
                        text: text,
                      });
                      if (res.status == 200) {
                        modal.success({
                          title: "comment updated successfully!",
                        });
                        setIsEdit(false);
                        await getComments(1, true, filter);
                      }
                    }}
                  >
                    Confirm
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "90%",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: comment.text }}
                    style={{ minWidth: "100%" }}
                  />
                </div>
              )}
              {isMobile && (
                <div
                  style={isMobile ? {} : { paddingRight: "20px" }}
                  className="col-12 py-1"
                  dir="rtl"
                >
                  <Avatar.Group>
                    {comment.assignees.map((w) => {
                      var hex = listOfColor[w.user.id % 20];
                      return (
                        <Avatar
                          size="small"
                          style={{
                            backgroundColor: `${hex}`,
                            cursor: "pointer",
                          }}
                          onClick={async () => {
                            Modal.confirm({
                              title:
                                "Are you sure you want to delete this assignee?",
                              icon: <ExclamationCircleFilled />,
                              okText: "Yes",
                              okType: "danger",
                              cancelText: "No",
                              onOk: async () => {
                                setIsLoading(true);
                                var res = await deleteData(
                                  `admin/comment-assignee/${w.id}`
                                );
                                if (res.status == 200) {
                                  let secondsToGo = 3;
                                  const instance = modal.success({
                                    title: "assignee deleted succesfully.",
                                  });
                                  const timer = setInterval(() => {
                                    secondsToGo -= 1;
                                    instance.update({});
                                  }, 1000);
                                  setTimeout(() => {
                                    clearInterval(timer);
                                    instance.destroy();
                                  }, secondsToGo * 1000);
                                  setIsLoading(false);
                                  await getComments(1, true, filter);
                                }
                              },
                              onCancel() {},
                            });
                          }}
                        >
                          <Tooltip title={w.user.email}>
                            {w.user.firstName != null
                              ? w.user.firstName[0] + w.user.lastName[0]
                              : w.user.email.substr(0, 3)}
                          </Tooltip>
                        </Avatar>
                      );
                    })}
                    <Avatar
                      size="small"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setaddAssignerModal(true);
                      }}
                    >
                      <Tooltip title={"Add new assignees"}>
                        <PlusOutlined />
                      </Tooltip>
                    </Avatar>
                  </Avatar.Group>
                </div>
              )}
              <div style={{ width: "auto" }}>
                <div className="row col-12 px-3 gap-3">
                  {comment.attachments.map((a) => {
                    return (
                      <div
                        className={isMobile ? "" : "col-12"}
                        style={
                          isMobile
                            ? {
                                border: "0.5px solid rgba(158, 158, 158, 0.53)",
                                padding: "4px 10px",
                                borderRadius: "5px",
                                fontSize: "11px",
                                // maxWidth: "max-content",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                justifyContent: "space-between",
                              }
                            : {
                                border: "0.5px solid rgba(158, 158, 158, 0.53)",
                                padding: "4px 10px",
                                borderRadius: "5px",
                                fontSize: "11px",
                                maxWidth: "max-content",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }
                        }
                      >
                        <div>
                          <PaperClipOutlined
                            style={{
                              marginRight: "5px",
                              fontSize: "17px",
                              color: "#1890ff",
                            }}
                            onClick={async () => {
                              setIsLoading(true);
                              var res = await fetchData(
                                `admin/comment-attachment/${a.id}/download`
                              );
                              if (res.status == 200) {
                                const link = document.createElement("a");
                                link.href = res.data.data.url;
                                link.target = "_blank";
                                link.click();
                              } else {
                                Modal.error({ title: res });
                              }
                              setIsLoading(false);
                            }}
                          />

                          <span
                            onClick={async () => {
                              setIsLoading(true);
                              var res = await fetchData(
                                `admin/comment-attachment/${a.id}/download`
                              );
                              if (res.status == 200) {
                                const link = document.createElement("a");
                                link.href = res.data.data.url;
                                link.target = "_blank";
                                link.click();
                              } else {
                                Modal.error({ title: res });
                              }
                              setIsLoading(false);
                            }}
                          >
                            {isMobile
                              ? a.title.length > 30
                                ? a.title.slice(0, 25)
                                : a.title
                              : a.title.length > 50
                              ? a.title.substr(0, 47) + "..."
                              : a.title}

                            {/* meoooooooooo */}
                          </span>
                        </div>
                        {privilage.indexOf(access.todoList.deleteAttachment) >
                          -1 && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11"
                            height="11"
                            fill="black"
                            class="bi bi-trash"
                            viewBox="0 0 16 16"
                            style={{ marginLeft: "5px" }}
                            onClick={async () => {
                              Modal.confirm({
                                title:
                                  "Are you sure you want to delete this attachment?",
                                icon: <ExclamationCircleFilled />,
                                okText: "Yes",
                                okType: "danger",
                                cancelText: "No",
                                onOk: async () => {
                                  setIsLoading(true);
                                  var res = await deleteData(
                                    `admin/comment-attachment/${a.id}`
                                  );
                                  if (res.status == 200) {
                                    let secondsToGo = 3;
                                    const instance = modal.success({
                                      title: "attachment deleted succesfully.",
                                    });
                                    const timer = setInterval(() => {
                                      secondsToGo -= 1;
                                      instance.update({});
                                    }, 1000);
                                    setTimeout(() => {
                                      clearInterval(timer);
                                      instance.destroy();
                                    }, secondsToGo * 1000);
                                    setIsLoading(false);
                                    await getComments(1, true, filter);
                                  }
                                },
                                onCancel() {},
                              });
                            }}
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                          </svg>
                        )}
                      </div>
                    );
                  })}
                  {privilage.indexOf(access.todoList.addAttachment) > -1 && (
                    <div
                      onClick={() => {
                        setAddAttachment(true);
                      }}
                      className={isMobile ? "" : "col-12"}
                      style={
                        isMobile
                          ? {
                              border: "0.5px solid rgba(158, 158, 158, 0.53)",
                              padding: "4px 10px",
                              borderRadius: "5px",
                              fontSize: "11px",
                              // maxWidth: "max-content",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                              // justifyContent: "space-between",
                            }
                          : {
                              border: "0.5px solid rgba(158, 158, 158, 0.53)",
                              padding: "4px 10px",
                              borderRadius: "5px",
                              fontSize: "11px",
                              maxWidth: "max-content",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }
                      }
                    >
                      <PlusOutlined
                        style={{
                          marginRight: "5px",
                          fontSize: "17px",
                          color: "#1890ff",
                        }}
                      />

                      <span>Add new Attachment</span>
                    </div>
                  )}
                </div>
              </div>
              {!comment.parentId && comment.replies.length > 0 && (
                <div
                  onClick={() => {
                    if (openReply) {
                      setOpenReply(false);
                    } else {
                      setOpenReply(true);
                    }
                  }}
                  style={
                    isMobile
                      ? {
                          marginTop: "5px",
                          color: "rgba(46, 108, 223, 1)",
                          padding: "2px",
                          fontSize: "8px",
                          fontWeight: "600",
                          cursor: "pointer",
                        }
                      : {
                          marginTop: "5px",
                          color: "rgba(46, 108, 223, 1)",
                          padding: "2px",

                          fontSize: "12px",
                          fontWeight: "600",
                          cursor: "pointer",
                        }
                  }
                >
                  <svg
                    style={{ marginRight: "5px" }}
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.625 4C13.7831 4 13.75 12.125 13.75 12.125C13.75 12.125 12.9581 8.375 5.625 8.375V11.5L0 6.0675L5.625 0.875V4Z"
                      fill="#2E6CDF"
                    />
                  </svg>
                  {openReply ? "Hide" : "Show"} Replies
                </div>
              )}
            </div>

            <div
            className="py-2"
              style={{
                paddingLeft: "10px",
                textAlign: "center",
                display: "grid",
                gridTemplateRows: "repeat(4,1fr)",
               
                alignItems: "center",
                justifyContent: "center",
                borderLeft:'1px solid rgba(158, 158, 158, 0.53)',
                borderColor:'#9E9E9E',
              marginLeft:'10px',
              

              }}
            >
              {privilage.indexOf(access.todoList.deleteComment) > -1 && (
                <Tooltip title={'Delete Comment'}>
                <svg
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ display: "block" }}
                  onClick={confirm}
                >
                  <path
                    d="M4.24268 13.2427L12.728 4.75739"
                    stroke="#9E9E9E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M12.728 13.2427L4.24275 4.75739"
                    stroke="#9E9E9E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
                </Tooltip>
              )}

              {privilage.indexOf(access.todoList.editComment) > -1 && (
                 <Tooltip title={"Show Viewer"}>
                <Dropdown
                trigger={['click']}
                  menu={{
                    items: items,
                  }}
                >
                  <svg
                    width="17"
                    height="11"
                    viewBox="0 0 17 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ display: "block" }}
                  >
                    <path
                      d="M16 5.5C14.892 2.86923 11.9432 1 8.5 1C5.05682 1 2.10795 2.86923 1 5.5C2.10795 8.13077 5.05682 10 8.5 10C11.9432 10 14.892 8.13077 16 5.5ZM6.36932 1.96923C5.19318 2.69615 4.40909 3.99423 4.40909 5.5C4.40909 7.00577 5.19318 8.30385 6.36932 9.03077C4.30682 8.51154 2.58523 7.21346 1.75 5.5C2.58523 3.78654 4.30682 2.48846 6.36932 1.96923ZM8.5 2.03846C10.375 2.03846 11.9091 3.59615 11.9091 5.5C11.9091 7.40385 10.375 8.96154 8.5 8.96154C6.625 8.96154 5.09091 7.40385 5.09091 5.5C5.09091 3.59615 6.625 2.03846 8.5 2.03846ZM15.25 5.5C14.4148 7.21346 12.6932 8.51154 10.6307 9.03077C11.8068 8.30385 12.5909 7.00577 12.5909 5.5C12.5909 3.99423 11.8068 2.69615 10.6307 1.96923C12.6932 2.48846 14.4148 3.78654 15.25 5.5Z"
                      fill={"#9E9E9E"}
                      stroke="#9E9E9E"
                      stroke-width="0.2"
                    />
                    <path
                      d="M10 5.5C10 5.1875 9.9 4.9 9.7375 4.6625C9.75 4.6875 9.75 4.7125 9.75 4.75C9.75 5.025 9.525 5.25 9.25 5.25C8.975 5.25 8.75 5.025 8.75 4.75C8.75 4.475 8.975 4.25 9.25 4.25C9.275 4.25 9.3125 4.2625 9.3375 4.2625C9.1 4.1 8.8125 4 8.5 4C7.675 4 7 4.675 7 5.5C7 6.325 7.675 7 8.5 7C9.325 7 10 6.325 10 5.5Z"
                      fill="#9E9E9E"
                      stroke="#9E9E9E"
                      stroke-width="0.2"
                    />
                  </svg>
                </Dropdown>
                </Tooltip>
              )}
              {privilage.indexOf(access.todoList.editComment) > -1 && (
                 <Tooltip title='Add Priority'>
                <Dropdown menu={{ items: falgItems, onClick: onclickFlag }} trigger={['click']}>
                  <FlagFilled
                    style={{
                      color:
                        comment.priority == 3
                          ? "#fa8c16"
                          : comment.priority == 2
                          ? "#ffec3d"
                          : comment.priority == 1
                          ? "#cf1322"
                          : "#52c41a",
                    }}
                  />
                </Dropdown>
                </Tooltip>
              )}
              {privilage.indexOf(access.todoList.editComment) > -1 && (
                 <Tooltip title={"Add Due Date"}>
             
              
                <DatePicker
                  renderExtraFooter={() => "Select new due date"}
                  onChange={async (value) => {
                    setIsLoading(true);

                    var res = await putData("admin/comment/" + comment.id, {
                      dueDate: value,
                    });
                    if (res.status == 200) {
                      Modal.success({ title: res.data.message });
                      await getComments(1, true, filter);
                    } else {
                      Modal.error({ title: res });
                    }
                    setIsLoading(false);
                  }}
                  placeholder=""
                  variant="borderless"
                  style={{ marginRight: "35px", marginLeft: "-13px" }}
                />
                 </Tooltip>
              )}
              {privilage.indexOf(access.todoList.editComment) > -1 && (
                  <Tooltip title={'Edit Comment'}>
                <EditOutlined
                  style={{
                    marginLeft: "5px",
                    marginTop: "5px",
                    color: "#9E9E9E",
                  }}
                  onClick={() => {
                    setIsEdit(true);
                  }}
                />
                </Tooltip>
              )}
            </div>
          </div>
        </Card>
      </div>
      {openReply && (
        <SingleComment
          open={openReply}
          comment={comment}
          getComments={getComments}
          users={Users}
          search={filter}
          purchase={purchase}
          get={getReply}
        />
      )}
      <Modal
        title="Add Viewer "
        open={addWatcherModal}
        confirmLoading={loading}
        onOk={async () => {
          setIsLoading(true);
          var res = await postData("admin/comment-watcher", {
            commentId: comment.id,
            userId: temp,
          });
          if (res.status == 201) {
            setaddWatcherModal(false);
            Modal.success({ title: "viewer added successfully" });
            // setwatcherValue("@");
            await getComments(1, true, filter);
          }
          setIsLoading(false);
          // console.log(res)
        }}
        onCancel={() => {
          setaddWatcherModal(false);
        }}
      >
        <Mentions
          placeholder="@email@example.com"
          value={watcherValue}
          onChange={(value) => setwatcherValue(value)}
          onSelect={(value) => {
            setTemp(
              Users.filter((u) => {
                return u.email == value.key;
              })[0].id
            );
          }}
          options={Users.map((u) => {
            if (comment.watchers.indexOf({ userId: u.id }) == -1)
              return { value: u.email, label: u.email };
          })}
        />
      </Modal>
      <Modal
        title="Add Assigner"
        open={addAssignerModal}
        confirmLoading={loading}
        onOk={async () => {
          setIsLoading(true);
          var res = await postData("admin/comment-assignee", {
            commentId: comment.id,
            userId: temp,
          });
          // console.log(res)
          if (res.status == 201) {
            setaddAssignerModal(false);
            Modal.success({ title: "Assignee added successfully" });
            // setAssignerValue("@");
            await getComments(1, true, filter);
          } else {
            Modal.error({ title: res });
          }
          setIsLoading(false);
        }}
        onCancel={() => {
          setaddAssignerModal(false);
        }}
      >
        <Mentions
        placeholder="@email@example.com"
          value={assignerValue}
          onChange={(value) => setAssignerValue(value)}
          onSelect={(value) => {
            setTemp(
              Users.filter((u) => {
                return u.email == value.key;
              })[0].id
            );
          }}
          options={Users.map((u) => {
            if (comment.assignees.indexOf({ userId: u.id }) == -1)
              return { value: u.email, label: u.email };
          })}
        />
      </Modal>
      <Modal
        open={addAttachment}
        onCancel={() => {
          setAddAttachment(false);
        }}
        okButtonProps={{ style: { display: "none" } }}
        destroyOnClose={true}
      >
        <div>
          <div className="d-flex flex-column  col-12 align-items-center justify-content-center">
            <h1 style={{ fontSize: "24px", fontWeight: "700" }}>Attachment</h1>
            <div className="d-flex flex-column gap-4 col-12 align-items-center justify-content-center">
              <div className="row col-12"></div>
              <div className="d-flex flex-row gap-3 mb-4 justify-content-center align-items-center col-9">
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CommentCard;

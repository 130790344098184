import { useState, useEffect } from "react";
import { useAssets, useMobile } from "../../hooks";
import { fetchData, postData, putData, deleteData } from "../../endpoints";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import "./styles.scss";
import { CustomInput } from "../../components/CustomInput";
import { CustomButton } from "../../components/CustomButton";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Checkbox, Radio, Modal, Spin } from "antd";
import { modalGlobalConfig } from "antd/es/modal/confirm";

const AddPatient = ({
  isOpen,
  setIsModalClose,
  p,
  userId,
  getPatients,
  isEdit = false,
  serviceTypes,
  editstep = 2,
}) => {
  const isMobile = useMobile();
  const [step, setStep] = useState(editstep);
  const [othersex, setothersex] = useState(false);
  const [othergender, setothergender] = useState(false);
  const [showService, setshowService] = useState("");
  const [Checkitems, setCheckitems] = useState([]);
  const [isfirst, setisfirst] = useState(true);
  const [patient, setPatient] = useState(
    isEdit
      ? p
      : {
          firstName: "",
          lastName: "",
          birthday: "",
          sex: "",
          gender: "",
          relationship: "",
          careCard: "",
          emergencyContacts: [
            {
              relationship: "",
              country: "",
              phoneNumber: "",
              itemOpen: true,
            },
          ],
          referralBy: {},
          teachers: [
            { firstName: "", lastName: "", email: "", itemOpen: true },
          ],
          serviceTypes: [],
        }
  );
  const [options, setOption] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState([]);
  const getServiceTypes = async () => {
    var res = await fetchData("admin/service-type");
    if (res.status == 200) {
      setServices(res.data.data);
    }
  };
  const { getFile } = useAssets();
  async function fetchUser() {
    setIsLoading(true);
    var res2 = await fetchData("user/config");
    // console.log(res2);
    if (res2.status === 200) {
      var canf = res2.data.data.profile;
      setOption({
        ...options,
        Gender: Object.values(res2.data.data.patient.gender),
        Province: Object.keys(Object.values(canf.cities)[0]),
        CareerPosition: Object.values(canf.careers),
        Country: Object.keys(canf.cities),
        City: canf.cities,
        Sex: Object.values(res2.data.data.patient.sex),
        Pronouns: Object.values(canf.pronoun),
        Prefix: Object.values(canf.prefix),
        Relationship: Object.values(res2.data.data.patient.relationship),
        Emergency: Object.values(
          res2.data.data.patient.emergencyContact.relationship
        ),
      });
    } else {
      Modal.error({
        title: res2,
      });
    }

    setIsLoading(false);
  }
  useEffect(() => {
    getServiceTypes();
    fetchUser();
  }, []);

  const addPatient = async () => {
    setIsLoading(true);
    if (!patient.firstName) {
      Modal.error({
        title: "First Name cannot be empty.",
      });
      setIsLoading(false);
      return;
    }
    if (!patient.lastName) {
      Modal.error({
        title: "Last Name cannot be empty.",
      });
      setIsLoading(false);
      return;
    }
    if (!patient.birthday) {
      Modal.error({
        title: "Date Of Birth cannot be empty.",
      });
      setIsLoading(false);
      return;
    }
    if (!patient.sex) {
      Modal.error({
        title: "Sex cannot be empty.",
      });
      setIsLoading(false);
      return;
    }
    if (!patient.gender) {
      Modal.error({
        title: "Gender Identity cannot be empty.",
      });
      setIsLoading(false);
      return;
    }
    if (!patient.careCard) {
      Modal.error({
        title: "Care Card cannot be empty.",
      });
      setIsLoading(false);
      return;
    }
    if (patient.emergencyContacts.length === 0) {
      Modal.error({
        title: "Emergnecy Contact cannot be empty.",
      });
      setIsLoading(false);
      return;
    }
    if (patient.serviceTypes.length === 0) {
      Modal.error({
        title: "Services cannot be empty.",
      });
      setIsLoading(false);
      return;
    }

    var res = await postData("admin/patient", {
      ...patient,
      userId: userId,
    });
    if (res.status === 201) {
      for (var i in patient.emergencyContacts) {
        var res2 = await postData(
          `admin/patient/${res.data.data.id}/emergency-contact`,
          patient.emergencyContacts[i]
        );
        if (res2.status != 201) {
          Modal.error({
            title: res2,
          });
        }
      }
      if (patient.teachers.length > 0) {
        for (var i in patient.teachers) {
          var res2 = await postData(
            `admin/patient/${res.data.data.id}/teacher`,
            patient.teachers[i]
          );
          if (res2.status != 201) {
            Modal.error({
              title: res2,
            });
          }
        }
      }
      for (var i in patient.serviceTypes) {
        // console.log(patient.serviceTypes[i].serviceType.id)
        var res2 = await postData(
          `admin/patient/${res.data.data.id}/service-type`,
          { serviceTypeId: patient.serviceTypes[i].serviceType.id }
        );
        if (res2.status != 201) {
          Modal.error({ title: res2 });
        }
      }

      if (patient.referralBy && patient.referralBy.firstName) {
        var res2 = await postData(
          `admin/patient/${res.data.data.id}/referral-by`,
          patient.referralBy
        );
        if (res2.status != 201) {
          Modal.error({
            title: res2,
          });
        }
      }
      setPatient({
        firstName: "",
        lastName: "",
        birthday: "",
        sex: "",
        gender: "",
        relationship: "",
        careCard: "",
        emergencyContacts: [
          {
            relationship: "",
            country: "",
            phoneNumber: "",
            itemOpen: true,
          },
        ],
        referralBy: {},
        teachers: [{ firstName: "", lastName: "", email: "", itemOpen: true }],
        serviceTypes: [],
      });

      await getPatients(null, userId);
    } else {
      Modal.error({
        title: res,
      });
    }
    setIsLoading(false);
  };
  const editPatient = async () => {
    setIsLoading(true);
    // if (!patient.firstName) {
    //   Modal.error({
    //     title: "First Name cannot be empty.",
    //   });
    //   setIsLoading(false);
    //   return;
    // }
    // if (!patient.lastName) {
    //   Modal.error({
    //     title: "Last Name cannot be empty.",
    //   });
    //   setIsLoading(false);
    //   return;
    // }
    // if (!patient.birthday) {
    //   Modal.error({
    //     title: "Date Of Birth cannot be empty.",
    //   });
    //   setIsLoading(false);
    //   return;
    // }
    // if (!patient.sex) {
    //   Modal.error({
    //     title: "Sex cannot be empty.",
    //   });
    //   setIsLoading(false);
    //   return;
    // }
    // if (!patient.gender) {
    //   Modal.error({
    //     title: "Gender Identity cannot be empty.",
    //   });
    //   setIsLoading(false);
    //   return;
    // }
    // if (!patient.careCard) {
    //   Modal.error({
    //     title: "Care Card cannot be empty.",
    //   });
    //   setIsLoading(false);
    //   return;
    // }
    // if (patient.emergencyContacts.length === 0) {
    //   Modal.error({
    //     title: "Emergnecy Contact cannot be empty.",
    //   });
    //   setIsLoading(false);
    //   return;
    // }
    // // if (patient.teachers.length === 0) {
    // //   Modal.error({
    // //     title: "Teachers/Care Provider Email cannot be empty.",
    // //   });
    // //   setIsLoading(false);
    // //   return;
    // // }
    // if (patient.serviceTypes.length === 0) {
    //   Modal.error({
    //     title: "Services cannot be empty.",
    //   });
    //   setIsLoading(false);
    //   return;
    // }
    var res = await putData("admin/patient/" + patient.id, patient);
    if (res.status === 200) {
      for (var i in patient.emergencyContacts) {
        if (patient.emergencyContacts[i].id) {
          var res2 = await putData(
            `admin/patient/${res.data.data.id}/emergency-contact/${patient.emergencyContacts[i].id}`,
            patient.emergencyContacts[i]
          );
          if (res2.status != 200) {
            Modal.error({
              title: res2,
            });
          }
        } else {
          var res2 = await postData(
            `admin/patient/${res.data.data.id}/emergency-contact`,
            patient.emergencyContacts[i]
          );
          if (res2.status != 201) {
            Modal.error({
              title: res2,
            });
          }
        }
      }
      for (var i in patient.teachers) {
        if (patient.teachers[i].id) {
          var res2 = await putData(
            `admin/patient/${res.data.data.id}/teacher/${patient.teachers[i].id}`,
            patient.teachers[i]
          );
          if (res2.status != 200) {
            Modal.error({
              title: res2,
            });
          }
        } else {
          var res2 = await postData(
            `admin/patient/${res.data.data.id}/teacher`,
            patient.teachers[i]
          );
          if (res2.status != 201) {
            Modal.error({
              title: "",
              text: res2,
              icon: "error",
              confirmButtonText: "ok",
            });
          }
        }
      }
      // console.log(patient.serviceTypes);
      for (var i in patient.serviceTypes) {
        if (patient.serviceTypes[i].serviceType.id) {
          // console.log(i, patient.serviceTypes[i].serviceType.id);
          var res2 = await putData(
            `admin/patient/${res.data.data.id}/service-type/${patient.serviceTypes[i].id}`,
            { serviceTypeId: patient.serviceTypes[i].serviceType.id }
          );
          if (res2.status != 200) {
            Modal.error({
              title: res2,
            });
          }
        } else {
          var res2 = await postData(
            `admin/patient/${res.data.data.id}/service-type`,
            { serviceTypeId: patient.serviceTypes[i] }
          );
          if (res2.status != 201) {
            Modal.error({
              title: res2,
            });
          }
        }
      }
      if (
        patient.referralBy &&
        (patient.referralBy.checked == undefined ||
          patient.referralBy.checked == false) &&
        patient.referralBy.firstName
      ) {
        if (patient.referralBy.id) {
          var res2 = await putData(
            `admin/patient/${res.data.data.id}/referral-by/${patient.referralBy.id}`,
            patient.referralBy
          );
          if (res2.status != 200) {
            Modal.error({
              title: res2,
            });
          }
        } else {
          var res2 = await postData(
            `admin/patient/${res.data.data.id}/referral-by`,
            patient.referralBy
          );
          if (res2.status != 201) {
            Modal.error({
              title: res2,
            });
          }
        }
      } else {
        if (patient.referralBy.id) {
          var res3 = await deleteData(
            `admin/patient/${res.data.data.id}/referral-by/${patient.referralBy.id}`
          );
          if (res3.status != 200) {
            Modal.error({
              title: res2,
            });
          }
        }
      }
      setPatient({
        firstName: "",
        lastName: "",
        birthday: "",
        sex: "",
        gender: "",
        relationship: "",
        careCard: "",
        emergencyContacts: [
          {
            relationship: "",
            country: "",
            phoneNumber: "",
            itemOpen: true,
          },
        ],
        teachers: [{ firstName: "", lastName: "", email: "", itemOpen: true }],
        referralBy: {},
        serviceTypes: [],
      });
      await fetchUser();
    } else {
      Modal.error({
        title: res,
      });
    }
    setIsModalClose(false);
    setIsLoading(false);
  };

  if (isOpen)
    return (
      <>
        <div className="d-flex w-100 mt-2" style={{ gap: "90%" }}>
          <svg
            style={{ justifySelf: "left" }}
            width="24"
            height="18"
            viewBox="0 0 24 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              if (step == 2) {
                setPatient({
                  firstName: "",
                  lastName: "",
                  birthday: "",
                  sex: "",
                  gender: "",
                  relationship: "",
                  careCard: "",
                  emergencyContacts: [
                    {
                      relationship: "",
                      country: "",
                      phoneNumber: "",
                      itemOpen: true,
                    },
                  ],
                  teachers: [
                    { firstName: "", lastName: "", email: "", itemOpen: true },
                  ],
                  referralBy: {},
                  serviceTypes: [],
                });
                setIsModalClose(false);
              } else {
                if (patient.referralBy == null) {
                  setPatient({ ...patient, referralBy: {} });
                }
                setStep(step - 1);
              }
            }}
          >
            <path
              d="M22.7996 8.39557H2.65334L10.0208 1.01421C10.2528 0.78219 10.2528 0.40603 10.0208 0.174013C9.7889 -0.0580043 9.41285 -0.0580043 9.18091 0.174013L0.781469 8.57595C0.665495 8.68473 0.599609 8.83691 0.599609 8.99605C0.599609 9.15519 0.665498 9.30737 0.781469 9.41615L9.18091 17.8181C9.28965 17.9341 9.44178 18 9.60088 18C9.75997 18 9.91211 17.9341 10.0208 17.8181C10.1368 17.7093 10.2027 17.5571 10.2027 17.398C10.2027 17.2388 10.1368 17.0867 10.0208 16.9779L2.65334 9.59653H22.7996C23.131 9.59653 23.3996 9.32781 23.3996 8.99639C23.3996 8.66498 23.131 8.39625 22.7996 8.39625V8.39557Z"
              fill="black"
            />
          </svg>
        </div>

        {(step === 2 || step === 3) &&
          PersonalInfo(
            patient,
            setPatient,
            options,
            setStep,
            step,
            setIsLoading,
            isLoading,
            isMobile,
            othersex,
            setothersex,
            othergender,
            setothergender,
            isEdit,
            editPatient,
            setIsModalClose,
            getPatients
          )}
        {step === 4 &&
          EmergnecyContact(
            patient,
            options,
            setPatient,
            setStep,
            getFile,
            isMobile,
            setIsLoading,
            isLoading,
            isEdit,
            editPatient,
            setIsModalClose,
            getPatients,
            isfirst,
            setisfirst
          )}
        {step === 5 &&
          EmailAddress(
            patient,
            setPatient,
            setStep,
            getFile,
            isMobile,
            setIsLoading,
            isLoading,
            isEdit,
            editPatient,
            setIsModalClose,
            getPatients,
            isfirst,
            setisfirst
          )}
        {step === 6 &&
          Referral(
            patient,
            setPatient,
            setStep,
            isMobile,
            isEdit,
            editPatient,
            setIsModalClose,
            getPatients,
            isLoading,
            isfirst,
            setisfirst
          )}
        {step === 7 &&
          Services(
            services,
            setStep,
            setIsModalClose,
            patient,
            setPatient,
            addPatient,
            editPatient,
            isEdit,
            showService,
            setshowService,
            Checkitems,
            setCheckitems,
            getPatients,
            isLoading
          )}
      </>
    );
};
const PersonalInfo = (
  patient,
  setPatient,
  options,
  setStep,
  step,
  setIsLoading,
  isLoading,
  isMobile,
  othersex,
  setothersex,
  othergender,
  setothergender,
  isEdit = false,
  editPatient,
  setIsModalClose,
  getPatients
) => {
  function insertBlankAfterEveryThreeCharacters(str) {
    var str = str.split(" ").join("").split("");
    var formatted = [];

    for (var i = 0; i < 4 && str.length; i++) {
      formatted.push(str.shift());
    }
    if (str.length) formatted.push(" ");
    while (str.length) {
      for (var i = 0; i < 3 && str.length; i++) {
        formatted.push(str.shift());
      }
      if (str.length) formatted.push(" ");
    }
    return formatted.join("");
  }
  return (
    <>
      <div className=" info d-flex flex-column justify-content-center align-items-center gap-4 py-2 px-5">
        <h2 style={isMobile ? { fontSize: "18px" } : {}}>
          Enter Patient’s Personal Information
        </h2>
        <CustomInput
          label={"First Name"}
          required
          fullWidth
          isRow={!isMobile}
          value={patient.firstName}
          onChange={(e) => {
            setPatient({ ...patient, firstName: e });
          }}
        />
        <CustomInput
          label={"Last Name"}
          required
          isRow={!isMobile}
          fullWidth
          value={patient.lastName}
          onChange={(e) => {
            setPatient({ ...patient, lastName: e });
          }}
        />

        <CustomInput
          label={"Sex"}
          select
          options={options.Sex}
          required
          fullWidth
          isRow={!isMobile}
          value={patient.sex}
          onChange={(e) => {
            if (e == "Other") {
              setPatient({ ...patient, sex: e });
              setothersex(true);
            } else {
              setothersex(false);
              setPatient({ ...patient, sex: e });
            }
          }}
        />
        {othersex || patient.sex == "Other" ? (
          <CustomInput
            label={"Other sex extra info"}
            required
            fullWidth
            isRow={!isMobile}
            value={patient.sexExtraInfo}
            onChange={(e) => {
              setPatient({ ...patient, sexExtraInfo: e });
            }}
          />
        ) : (
          <></>
        )}
        <CustomInput
          label={"Gender Identity"}
          select
          options={options.Gender}
          required
          fullWidth
          isRow={!isMobile}
          value={patient.gender}
          onChange={(e) => {
            if (e == "Other") {
              setPatient({ ...patient, gender: e });
              setothergender(true);
            } else {
              setothergender(false);
              setPatient({ ...patient, gender: e });
            }
          }}
        />
        {othergender || patient.gender == "Other" ? (
          <CustomInput
            label={"Other gender extra info"}
            required
            fullWidth
            isRow={!isMobile}
            value={patient.genderExtraInfo}
            onChange={(e) => {
              setPatient({ ...patient, genderExtraInfo: e });
            }}
          />
        ) : (
          <></>
        )}
        <div
          className={`d-flex w-100 gap-3 ${
            isMobile ? "flex-column" : "flex-row align-items-center "
          } `}
          style={{ marginLeft: "10px" }}
        >
          <label
            className="col-lg-3"
            style={{ fontSize: "12px", fontWeight: "600", paddingLeft: "5px" }}
          >
            Date Of Birth
            <span
              style={{
                fontSize: "17px",
                marginBottom: "20px",
                marginLeft: "2px",
                color: "red",
              }}
            >
              *
            </span>
          </label>

          <DatePicker
            inputPlaceholder="Select a day"
            shouldHighlightWeekends
            calendarClassName="responsive-calendar"
            wrapperClassName="col-12 col-lg-8 ml-1"
            value={
              patient.birthday
                ? {
                    year: new Date(patient.birthday).getFullYear(),
                    month: new Date(patient.birthday).getMonth(),
                    day: new Date(patient.birthday).getDate(),
                  }
                : null
            }
            onChange={(e) => {
              setPatient({
                ...patient,
                birthday: new Date(e.year, e.month, e.day),
              });
            }}
          />
        </div>
        {step === 2 ? (
          <CustomInput
            label={"Relationship"}
            select
            options={options.Relationship}
            required
            fullWidth
            isRow={!isMobile}
            value={patient.relationship}
            onChange={(e) => {
              setPatient({ ...patient, relationship: e });
            }}
          />
        ) : null}
        <CustomInput
          label={"Care Card(PHN)"}
          required
          fullWidth
          isRow={!isMobile}
          value={insertBlankAfterEveryThreeCharacters(patient.careCard)}
          maxlength={12}
          onChange={(e) => {
            setPatient({ ...patient, careCard: e });
          }}
        />
        <CustomButton
          loading={isLoading}
          title={isEdit ? "Save" : "Next"}
          uiType="primary"
          onClick={async () => {
            if (!patient.firstName) {
              Modal.error({
                title: "First Name cannot be empty.",
              });
              setIsLoading(false);
              return;
            }
            if (!patient.lastName) {
              Modal.error({
                title: "Last Name cannot be empty.",
              });
              setIsLoading(false);
              return;
            }
            if (!patient.birthday) {
              Modal.error({
                title: "Date Of Birth cannot be empty.",
              });
              setIsLoading(false);
              return;
            }
            if (!patient.sex) {
              Modal.error({
                title: "Sex cannot be empty.",
              });
              setIsLoading(false);
              return;
            }
            if (!patient.gender) {
              Modal.error({
                title: "Gender Identity cannot be empty.",
              });
              setIsLoading(false);
              return;
            }
            if (!patient.careCard) {
              Modal.error({
                title: "Care Card cannot be empty.",
              });
              setIsLoading(false);
              return;
            }
            if (isEdit) {
              var res = await putData("admin/patient/" + patient.id, patient);
              if (res.status != 200) {
                Modal.error({ title: res });
              } else {
                setIsModalClose(false);
                await getPatients();
              }

              return;
            }
            setStep(4);
          }}
        />
      </div>
    </>
  );
};
const EmergnecyContact = (
  patient,
  options,
  setPatient,
  setStep,
  getFile,
  isMobile,
  setLoading,
  isLoading,
  isEdit = false,
  editPatient,
  setIsModalClose,
  getPatients,
  isfirst,
  setisfirst
) => {
  return (
    <div className="info d-flex flex-column justify-content-center align-items-center gap-5 py-2 px-5">
      <h2
        style={
          isMobile
            ? { fontSize: "18px", marginBottom: "-20px" }
            : { marginBottom: "-30px" }
        }
      >
        Enter Patient’s Emergnecy Contact
      </h2>

      {patient.emergencyContacts.map((e, i) => {
        return (
          <>
            {e.itemOpen && (
              <>
                <div className="d-flex flex-row align-items-start pt-3 w-100">
                  {/* <div className="col-1" /> */}
                  <div className="col-10 d-flex flex-column gap-2 align-items-center">
                    <div
                      style={
                        !isMobile
                          ? {
                              alignItems: "center",
                              marginLeft: "20px",
                              marginRight: "15px",
                              paddingLeft: "10px",
                            }
                          : { marginLeft: "20px", marginRight: "15px" }
                      }
                      className={
                        !isMobile
                          ? "d-flex flex-row w-100 gap-2"
                          : "d-flex flex-column w-100 gap-1"
                      }
                    >
                      <span
                        style={{ fontSize: "12px", fontWeight: "600" }}
                        className="col-3 country"
                      >
                        Phone Number
                      </span>
                      <PhoneInput
                        className="col-11 col-lg-8 my-1 phon-input"
                        placeholder="Enter phone number"
                        value={e.phoneNumber}
                        defaultCountry="CA"
                        error={
                          e.phoneNumber
                            ? isValidPhoneNumber(e.phoneNumber)
                              ? undefined
                              : "Invalid phone number"
                            : "Phone number required"
                        }
                        onChange={(v) => {
                          let updated = patient.emergencyContacts;
                          updated[i].phoneNumber = v;
                          setPatient({
                            ...patient,
                            emergencyContacts: updated,
                          });
                        }}
                      />
                    </div>
                    {patient.emergencyContacts[i].phoneNumber &&
                    isValidPhoneNumber(
                      patient.emergencyContacts[i].phoneNumber
                    ) ? (
                      <></>
                    ) : (
                      !isfirst && (
                        <div
                          style={
                            isMobile
                              ? {
                                  color: "red",
                                  display: "flex",
                                  marginTop: "-13px",
                                }
                              : {
                                  color: "red",
                                  display: "flex",
                                  marginTop: "-10px",
                                  marginLeft: "-50px",
                                }
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="red"
                            class="bi bi-x-circle-fill"
                            viewBox="0 0 16 16"
                            style={{ marginRight: "5px" }}
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                          </svg>{" "}
                          <div
                            style={
                              isMobile
                                ? { fontSize: "10px" }
                                : { fontSize: "12px" }
                            }
                          >
                            Phone number isn't valid
                          </div>
                        </div>
                      )
                    )}
                    <CustomInput
                      label={"Name"}
                      // select
                      options={options.Relationship}
                      required
                      isRow={!isMobile}
                      fullWidth
                      value={e.fullName}
                      onChange={(v) => {
                        let updated = patient.emergencyContacts;
                        updated[i].fullName = v;
                        setPatient({
                          ...patient,
                          emergencyContacts: updated,
                        });
                      }}
                    />
                    {patient.emergencyContacts[i].fullName ? (
                      <></>
                    ) : (
                      !isfirst && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            // alignItems: "center",
                            marginLeft: "-60px",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="red"
                            class="bi bi-x-circle-fill"
                            viewBox="0 0 16 16"
                            style={{ marginRight: "5px" }}
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                          </svg>{" "}
                          <div
                            style={
                              isMobile
                                ? { fontSize: "10px" }
                                : { fontSize: "12px" }
                            }
                          >
                            {" "}
                            Please enter the name
                          </div>
                        </div>
                      )
                    )}

                    <CustomInput
                      label={"Relationship"}
                      select
                      options={options.Emergency}
                      required
                      isRow={!isMobile}
                      fullWidth
                      value={e.relationship}
                      onChange={(v) => {
                        let updated = patient.emergencyContacts;
                        updated[i].relationship = v;
                        setPatient({
                          ...patient,
                          emergencyContacts: updated,
                        });
                      }}
                    />
                    {patient.emergencyContacts[i].relationship ? (
                      <></>
                    ) : (
                      !isfirst && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "-45px",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="red"
                            class="bi bi-x-circle-fill"
                            viewBox="0 0 16 16"
                            style={{ marginRight: "5px" }}
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                          </svg>{" "}
                          <div
                            style={
                              isMobile
                                ? { fontSize: "10px" }
                                : { fontSize: "12px" }
                            }
                          >
                            {" "}
                            Please select relationship
                          </div>
                        </div>
                      )
                    )}

                    <CustomButton
                      title={"add"}
                      uiType="primary2"
                      loading={isLoading}
                      style={{ marginLeft: "-5rem", marginTop: "1rem" }}
                      additionalClassNames=" col-3 text-align-center"
                      onClick={() => {
                        if (!e.relationship) {
                          Modal.error({
                            title: "Relationship cannot be empty.",
                          });
                          return;
                        }
                        if (!e.phoneNumber) {
                          Modal.error({
                            title: "Phone Number cannot be empty.",
                          });
                          return;
                        }
                        if (!isValidPhoneNumber(e.phoneNumber)) {
                          Modal.error({
                            title: "Invalid Phone Number.",
                          });
                          return;
                        }
                        let updated = patient.emergencyContacts;
                        updated[i].itemOpen = false;
                        setPatient({
                          ...patient,
                          emergencyContacts: updated,
                        });
                      }}
                    />
                  </div>
                  <div className="col-1" />
                  {i !== 0 &&
                    (!isLoading ? (
                      <img
                        className="col-1 px-1 py-1"
                        src={getFile("minesIcon")}
                        style={
                          isMobile
                            ? {
                                cursor: "pointer",
                                width: "35px",
                                marginTop: "10px",
                              }
                            : { cursor: "pointer", width: "35px" }
                        }
                        onClick={async () => {
                          if (e.id) {
                            setLoading(true);
                            var res = await deleteData(
                              `admin/patient/${patient.id}/emergency-contact/${e.id}`
                            );
                            if (res.status == 200) {
                              setPatient({
                                ...patient,
                                emergencyContacts:
                                  patient.emergencyContacts.filter(
                                    (e, index) => {
                                      return index != i;
                                    }
                                  ),
                              });
                            }
                            setLoading(false);
                          } else {
                            setPatient({
                              ...patient,
                              emergencyContacts:
                                patient.emergencyContacts.filter((e, index) => {
                                  return index != i;
                                }),
                            });
                          }
                        }}
                      />
                    ) : (
                      <Spin />
                    ))}
                </div>
                <div className="add-patient_line" />
              </>
            )}
            {!e.itemOpen && (
              <>
                <div className="colaps-emerjency py-2 ">
                  <span>{e.relationship}</span>
                  <div
                    onClick={() => {
                      var updated = patient.emergencyContacts;
                      updated[i].itemOpen = true;
                      setPatient({
                        ...patient,
                        emergencyContacts: updated,
                      });
                    }}
                  >
                    {" "}
                    <svg
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.5">
                        <path
                          d="M9.46769 5.56373L9.65168 5.74759L2.89883 12.5004H0V9.60174L6.75285 2.84857L9.28394 5.37966L9.46769 5.56373Z"
                          fill="black"
                        />
                        <path
                          d="M11.7497 3.64936L10.2641 5.13499L7.36523 2.23616L8.85086 0.750534C9.18503 0.416489 9.72881 0.416489 10.0629 0.750534L11.7498 2.43749C12.0837 2.77141 12.0837 3.31509 11.7497 3.64936H11.7497Z"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="add-patient_line" />
              </>
            )}
          </>
        );
      })}

      <div className="row align-items-center w-100">
        <img
          src={getFile("plusIcon")}
          style={{ cursor: "pointer", width: "55px" }}
          onClick={() => {
            setPatient({
              ...patient,
              emergencyContacts: [
                ...patient.emergencyContacts,
                {
                  relationship: "",
                  country: "",
                  phoneNumber: "",
                  itemOpen: true,
                },
              ],
            });
          }}
        />
      </div>
      <CustomButton
        loading={isLoading}
        title={isEdit ? "Save" : "Next"}
        uiType="primary"
        onClick={async () => {
          for (var i = 0; i < patient.emergencyContacts.length; ) {
            if (
              isValidPhoneNumber(patient.emergencyContacts[i].phoneNumber) &&
              patient.emergencyContacts[i].fullName &&
              patient.emergencyContacts[i].relationship
            ) {
              i++;
            } else {
              Modal.error({
                title: "please enter all informtion",
              });
              setisfirst(false);
              return;
            }
          }
          if (isEdit) {
            for (var i in patient.emergencyContacts) {
              if (patient.emergencyContacts[i].id) {
                var res2 = await putData(
                  `admin/patient/${patient.id}/emergency-contact/${patient.emergencyContacts[i].id}`,
                  patient.emergencyContacts[i]
                );
                if (res2.status != 200) {
                  Modal.error({
                    title: res2,
                  });
                }
              } else {
                var res2 = await postData(
                  `admin/patient/${patient.id}/emergency-contact`,
                  patient.emergencyContacts[i]
                );
                if (res2.status != 201) {
                  Modal.error({
                    title: res2,
                  });
                }
              }
            }
            setIsModalClose(false);
            await getPatients();
            setisfirst(true);
            return;
          }
          setisfirst(true);
          setStep(5);
        }}
      />
    </div>
  );
};
const EmailAddress = (
  patient,
  setPatient,
  setStep,
  getFile,
  isMobile,
  setLoading,
  isLoading,
  isEdit = false,
  editPatient,
  setIsModalClose,
  getPatients,
  isfirst,
  setisfirst
) => {
  function EmailValidate(email) {
    return email
      .toLowerCase()
      .match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  }
  return (
    <div className="info d-flex flex-column justify-content-center align-items-center gap-5 py-5 px-2">
      <h2 style={isMobile ? { fontSize: "18px" } : {}}>
        Enter Teacher/Care Provider Email Address
      </h2>
      {patient.teachers.map((e, i) => {
        return (
          <>
            {e.itemOpen && (
              <>
                <div className="d-flex flex-row align-items-start pt-3 w-100">
                  <div className="col-10 d-flex flex-column gap-3 align-items-center">
                    <CustomInput
                      label={"First Name"}
                      required
                      isRow={!isMobile}
                      fullWidth
                      value={e.firstName}
                      onChange={(v) => {
                        let updated = patient.teachers;
                        updated[i].firstName = v;
                        setPatient({
                          ...patient,
                          teachers: updated,
                        });
                      }}
                    />
                    {patient.teachers[i].firstName ? (
                      <></>
                    ) : (
                      !isfirst && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="red"
                            class="bi bi-x-circle-fill"
                            viewBox="0 0 16 16"
                            style={{ marginRight: "5px" }}
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                          </svg>{" "}
                          <div
                            style={
                              isMobile
                                ? { fontSize: "10px" }
                                : { fontSize: "15px" }
                            }
                          >
                            {" "}
                            Please enter first Name
                          </div>
                        </div>
                      )
                    )}
                    <CustomInput
                      label={"Last Name"}
                      required
                      isRow={!isMobile}
                      fullWidth
                      value={e.lastName}
                      onChange={(v) => {
                        let updated = patient.teachers;
                        updated[i].lastName = v;
                        setPatient({
                          ...patient,
                          teachers: updated,
                        });
                      }}
                    />
                    {patient.teachers[i].lastName ? (
                      <></>
                    ) : (
                      !isfirst && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="red"
                            class="bi bi-x-circle-fill"
                            viewBox="0 0 16 16"
                            style={{ marginRight: "5px" }}
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                          </svg>{" "}
                          <div
                            style={
                              isMobile
                                ? { fontSize: "10px" }
                                : { fontSize: "15px" }
                            }
                          >
                            {" "}
                            Please enter Last Name
                          </div>
                        </div>
                      )
                    )}
                    <CustomInput
                      label={"Email"}
                      required
                      isRow={!isMobile}
                      fullWidth
                      value={e.email}
                      onChange={(v) => {
                        let updated = patient.teachers;
                        updated[i].email = v;
                        setPatient({
                          ...patient,
                          teachers: updated,
                        });
                      }}
                    />
                    {patient.teachers[i].email &&
                    EmailValidate(patient.teachers[i].email) ? (
                      <></>
                    ) : (
                      !isfirst && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="red"
                            class="bi bi-x-circle-fill"
                            viewBox="0 0 16 16"
                            style={{ marginRight: "5px" }}
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                          </svg>{" "}
                          <div
                            style={
                              isMobile
                                ? { fontSize: "10px" }
                                : { fontSize: "15px" }
                            }
                          >
                            {" "}
                            Email address isn't valid
                          </div>
                        </div>
                      )
                    )}

                    <CustomButton
                      title={"add"}
                      uiType="primary2"
                      loading={isLoading}
                      style={{ marginLeft: "-1.2rem", marginTop: "1rem" }}
                      additionalClassNames=" col-4 text-align-center"
                      onClick={() => {
                        if (!e.firstName) {
                          Modal.error({
                            title: "First Name cannot be empty.",
                          });
                          return;
                        }
                        if (!e.lastName) {
                          Modal.error({
                            title: "Last Name cannot be empty.",
                          });
                          return;
                        }
                        if (!e.email) {
                          Modal.error({
                            title: "Email cannot be empty.",
                          });
                          return;
                        }
                        let updated = patient.teachers;
                        updated[i].itemOpen = false;
                        setPatient({
                          ...patient,
                          teachers: updated,
                        });
                      }}
                    />
                  </div>
                  <div className="col-1" />
                  {i !== 0 &&
                    (!isLoading ? (
                      <img
                        className="col-1 px-1 py-1"
                        src={getFile("minesIcon")}
                        style={
                          isMobile
                            ? {
                                cursor: "pointer",
                                width: "35px",
                                marginTop: "10px",
                              }
                            : { cursor: "pointer" }
                        }
                        onClick={async () => {
                          if (e.id) {
                            setLoading(true);
                            var res = await deleteData(
                              `admin/patient/${patient.id}/teacher/${e.id}`
                            );
                            if (res.status == 200) {
                              setPatient({
                                ...patient,
                                teachers: patient.teachers.filter(
                                  (e, index) => {
                                    return index != i;
                                  }
                                ),
                              });
                            }
                            setLoading(false);
                          } else
                            setPatient({
                              ...patient,
                              teachers: patient.teachers.filter((e, index) => {
                                return index != i;
                              }),
                            });
                        }}
                      />
                    ) : (
                      <Spin />
                    ))}
                </div>
                <div className="add-patient_line" />
              </>
            )}
            {!e.itemOpen && (
              <>
                <div className="colaps-emerjency py-2 ">
                  <span>{e.firstName + " " + e.lastName}</span>
                  <div
                    onClick={() => {
                      var updated = patient.teachers;
                      updated[i].itemOpen = true;
                      setPatient({
                        ...patient,
                        teachers: updated,
                      });
                    }}
                  >
                    {" "}
                    <svg
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.5">
                        <path
                          d="M9.46769 5.56373L9.65168 5.74759L2.89883 12.5004H0V9.60174L6.75285 2.84857L9.28394 5.37966L9.46769 5.56373Z"
                          fill="black"
                        />
                        <path
                          d="M11.7497 3.64936L10.2641 5.13499L7.36523 2.23616L8.85086 0.750534C9.18503 0.416489 9.72881 0.416489 10.0629 0.750534L11.7498 2.43749C12.0837 2.77141 12.0837 3.31509 11.7497 3.64936H11.7497Z"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="add-patient_line" />
              </>
            )}
          </>
        );
      })}
      <div className="row align-items-center w-100">
        <img
          src={getFile("plusIcon")}
          style={{ cursor: "pointer", width: "55px" }}
          onClick={() => {
            setPatient({
              ...patient,
              teachers: [
                ...patient.teachers,
                {
                  firstName: "",
                  lastName: "",
                  email: "",
                  itemOpen: true,
                },
              ],
            });
          }}
        />
      </div>
      {isEdit ? (
        <div className="d-flex flex-row gap-2">
          <CustomButton
            loading={isLoading}
            title={"Save"}
            uiType="primary"
            onClick={async () => {
              for (var i = 0; i < patient.teachers.length; ) {
                if (
                  patient.teachers[i].firstName &&
                  patient.teachers[i].lastName &&
                  patient.teachers[i].email &&
                  EmailValidate(patient.teachers[i].email)
                ) {
                  i++;
                } else {
                  Modal.error({
                    title: "please fill all information",
                  });
                  setisfirst(false);
                  return;
                }
              }
              if (
                patient.teachers.length < 1 ||
                (patient.teachers.length === 1 && patient.teachers[0].itemOpen)
              ) {
                Modal.error({
                  title:
                    "teachers/care provider cannot be empty. please enter or skip",
                });
                return;
              }
              if (patient.referralBy == null) {
                setPatient({ ...patient, referralBy: {} });
              }
              if (isEdit) {
                for (var i in patient.teachers) {
                  if (patient.teachers[i].id) {
                    var res2 = await putData(
                      `admin/patient/${patient.id}/teacher/${patient.teachers[i].id}`,
                      patient.teachers[i]
                    );
                    if (res2.status != 200) {
                      Modal.error({
                        title: res2,
                      });
                    }
                  } else {
                    var res2 = await postData(
                      `admin/patient/${patient.id}/teacher`,
                      patient.teachers[i]
                    );
                    if (res2.status != 201) {
                      Modal.error({
                        title: "",
                        text: res2,
                        icon: "error",
                        confirmButtonText: "ok",
                      });
                    }
                  }
                }
                setisfirst(true);
                setIsModalClose(false);
                await getPatients();
              }
            }}
          />
        </div>
      ) : (
        <div className="d-flex flex-row gap-2">
          <CustomButton
            title={"Skip"}
            uiType="primary2"
            onClick={() => {
              setPatient({ ...patient, teachers: [] });
              if (patient.referralBy == null) {
                setPatient({ ...patient, referralBy: {} });
              }
              setStep(6);
            }}
          />
          <CustomButton
            title={"Next"}
            uiType="primary"
            onClick={() => {
              for (var i = 0; i < patient.teachers.length; ) {
                if (
                  patient.teachers[i].firstName &&
                  patient.teachers[i].lastName &&
                  patient.teachers[i].email &&
                  EmailValidate(patient.teachers[i].email)
                ) {
                  i++;
                } else {
                  Modal.error({
                    title: "please fill all information",
                  });
                  setisfirst(false);
                  return;
                }
              }
              if (
                patient.teachers.length < 1 ||
                (patient.teachers.length === 1 && patient.teachers[0].itemOpen)
              ) {
                Modal.error({
                  title:
                    "teachers/care provider cannot be empty. please enter or skip",
                });
                return;
              }
              if (patient.referralBy == null) {
                setPatient({ ...patient, referralBy: {} });
              }
              setisfirst(true);
              setStep(6);
            }}
          />
        </div>
      )}
    </div>
  );
};
const Services = (
  services,
  setStep,
  setIsModalClose,
  patient,
  setPatient,
  addPatient,
  editPatient,
  isEdit,
  showService,
  setshowService,
  Checkitems,
  setCheckitems,
  getPatients,
  isLoading
) => {
  return (
    <div className="d-flex flex-column">
      <h2
        style={{
          fontSize: "35px",
          fontWeight: " 700",
          lineHeight: "42px",
          textAlign: "center",
        }}
      >
        Choose at least one service
      </h2>
      {console.log(services)}

      {services.map((s, i) => {
        return s.children.length > 0 ? (
          <div className="services d-flex flex-column">
            <div style={{ display: "flex", alignItems: "center" }}>
              {!Checkitems[i] ? (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: "5px" }}
                >
                  <mask id="path-1-inside-1_3497_120561" fill="white">
                    <rect width="16" height="16" rx="1" />
                  </mask>
                  <rect
                    width="16"
                    height="16"
                    rx="1"
                    stroke="#A1813A"
                    stroke-width="3"
                    mask="url(#path-1-inside-1_3497_120561)"
                  />
                </svg>
              ) : (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: "5px" }}
                >
                  <mask id="path-1-inside-1_3497_120531" fill="white">
                    <rect width="16" height="16" rx="1" />
                  </mask>
                  <rect
                    width="16"
                    height="16"
                    rx="1"
                    stroke="#A1813A"
                    stroke-width="3"
                    mask="url(#path-1-inside-1_3497_120531)"
                  />
                  <rect
                    x="3.2002"
                    y="3.20001"
                    width="9.6"
                    height="9.6"
                    rx="1"
                    fill="#A1813A"
                  />
                </svg>
              )}
              <div
                style={{ cursor: "pointer", fontWeight: "600" }}
                onClick={() => {
                  showService == s.title
                    ? setshowService("")
                    : setshowService(s.title);
                }}
              >
                {s.title}
              </div>
            </div>
            <div
              // className="d-flex flex-column d-none"
              style={
                showService == s.title
                  ? {
                      display: "none",
                      display: "flex",
                      flexDirection: "column",
                    }
                  : {
                      display: "none",
                      display: "flex",
                      flexDirection: "column",
                      display: "none",
                    }
              }
            >
              {s.children.map((c) => {
                return (
                  <label className="radio" style={{ marginTop: "5px" }}>
                    <input
                      className="radio-input"
                      type="checkbox"
                      checked={
                        patient.serviceTypes.filter((s) => {
                          return s.serviceType.id == c.id;
                        }).length > 0
                      }
                      onChange={(e) => {
                        var index = patient.serviceTypes.filter((s) => {
                          return s.serviceType.id == c.id;
                        });

                        if (index.length == 0) {
                          setPatient({
                            ...patient,
                            serviceTypes: [
                              ...patient.serviceTypes,
                              { serviceType: c },
                            ],
                          });
                          let temp = Checkitems;
                          temp[i] = true;
                          setCheckitems(temp);
                        } else {
                          if (
                            patient.serviceTypes.filter((c) => {
                              return c.serviceType.parentId == s.id;
                            }).length == 1
                          ) {
                            let temp = Checkitems;
                            temp[i] = false;
                            setCheckitems(temp);
                          }
                          setPatient({
                            ...patient,
                            serviceTypes: patient.serviceTypes.filter((s) => {
                              return s.serviceType.id != c.id;
                            }),
                          });
                        }
                      }}
                      // onChange={(e) => {
                      //   var index = patient.serviceTypes.indexOf(c.id);
                      //   if (index === -1) {
                      //     setPatient({
                      //       ...patient,
                      //       serviceTypes: [...patient.serviceTypes, c.id],
                      //     });
                      //   } else {
                      //     setPatient({
                      //       ...patient,
                      //       serviceTypes: patient.serviceTypes.splice(index, 1),
                      //     });
                      //   }
                      // }}
                    />
                    <span className="custom-radio" />
                    {c.title}
                  </label>
                );
              })}
            </div>
            <hr />
          </div>
        ) : null;
      })}

      <div
        style={{ justifyContent: "center" }}
        className="d-flex flex-row w-100 my-5 py-3"
      >
        <CustomButton
          loading={isLoading}
          title={isEdit ? "Save" : "Next"}
          uiType="primary"
          additionalClassNames="col-3"
          onClick={async () => {
            if (patient.serviceTypes.length < 1) {
              Modal.error({
                title: "Services cannot be empty.",
              });
              return;
            }
            if (isEdit) {
              for (var i in patient.serviceTypes) {
                if (patient.serviceTypes[i].id) {
                  var res2 = await putData(
                    `admin/patient/${patient.id}/service-type/${patient.serviceTypes[i].id}`,
                    { serviceTypeId: patient.serviceTypes[i].serviceType.id }
                  );
                  if (res2.status != 200) {
                    Modal.error({
                      title: res2,
                    });
                  }
                } else {
                  var res2 = await postData(
                    `admin/patient/${patient.id}/service-type`,
                    { serviceTypeId: patient.serviceTypes[i].serviceType.id }
                  );
                  if (res2.status != 201) {
                    Modal.error({
                      title: res2,
                    });
                  }
                }
              }
              await getPatients();
              setIsModalClose(false);
            } else {
              // console.log(patient.serviceTypes)
              addPatient();
            }

            setStep(2);
            setIsModalClose(false);
          }}
        />
      </div>
    </div>
  );
};

const Referral = (
  patient,
  setPatient,
  setStep,
  isMobile,
  isEdit = false,
  editPatient,
  setIsModalClose,
  getPatients,
  isLoading,
  isfirst,
  setisfirst
) => {
  function EmailValidate(email) {
    return email
      .toLowerCase()
      .match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  }
  return (
    <div className="info d-flex flex-column justify-content-center align-items-center gap-5 py-5 px-2">
      <h2 style={isMobile ? { fontSize: "18px" } : {}}>
        Enter Your Referring Doctor’s Information
      </h2>
      <div className="col-12 d-flex flex-column gap-3 align-items-center ">
        <CustomInput
          label={"First Name"}
          required
          isRow={!isMobile}
          fullWidth
          value={patient.referralBy ? patient.referralBy.firstName : ""}
          onChange={(v) => {
            let updated = patient.referralBy ? patient.referralBy : {};
            updated.firstName = v;
            setPatient({
              ...patient,
              referralBy: updated,
            });
          }}
        />

        {(patient.referralBy && patient.referralBy.checked == true) ||
        (patient.referralBy && patient.referralBy.firstName) ? (
          <></>
        ) : (
          !isfirst && (
            <div
              style={{
                color: "red",
                display: "flex",
                alignItems: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="red"
                class="bi bi-x-circle-fill"
                viewBox="0 0 16 16"
                style={{ marginRight: "5px" }}
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
              </svg>{" "}
              <div
                style={isMobile ? { fontSize: "10px" } : { fontSize: "15px" }}
              >
                please enter first Name
              </div>
            </div>
          )
        )}
        <CustomInput
          label={"Last Name"}
          required
          isRow={!isMobile}
          fullWidth
          value={patient.referralBy ? patient.referralBy.lastName : ""}
          onChange={(v) => {
            let updated = patient.referralBy ? patient.referralBy : {};
            updated.lastName = v;
            setPatient({
              ...patient,
              referralBy: updated,
            });
          }}
        />
        {(patient.referralBy && patient.referralBy.checked == true) ||
        (patient.referralBy && patient.referralBy.lastName) ? (
          <></>
        ) : (
          !isfirst &&
          <div
            style={{
              color: "red",
              display: "flex",
              alignItems: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="red"
              class="bi bi-x-circle-fill"
              viewBox="0 0 16 16"
              style={{ marginRight: "5px" }}
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
            </svg>{" "}
            <div style={isMobile ? { fontSize: "10px" } : { fontSize: "15px" }}>
              please enter lastName
            </div>
          </div>
        )}
        <CustomInput
          label={"Email"}
          required
          isRow={!isMobile}
          fullWidth
          value={patient.referralBy ? patient.referralBy.email : ""}
          onChange={(v) => {
            let updated = patient.referralBy ? patient.referralBy : {};
            updated.email = v;
            setPatient({
              ...patient,
              referralBy: updated,
            });
          }}
        />

        {(patient.referralBy && patient.referralBy.checked == true) ||
        (patient.referralBy?.email &&
          EmailValidate(patient.referralBy?.email)) ? (
          <></>
        ) : (
          !isfirst &&
          <div
            style={{
              color: "red",
              display: "flex",
              alignItems: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="red"
              class="bi bi-x-circle-fill"
              viewBox="0 0 16 16"
              style={{ marginRight: "5px" }}
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
            </svg>{" "}
            <div style={isMobile ? { fontSize: "10px" } : { fontSize: "15px" }}>
              please enter a correct email
            </div>
          </div>
        )}

        <CustomInput
          label={"Fax"}
          isRow={!isMobile}
          fullWidth
          value={patient.referralBy ? patient.referralBy.fax : ""}
          onChange={(v) => {
            let updated = patient.referralBy ? patient.referralBy : {};
            updated.fax = v;
            setPatient({
              ...patient,
              referralBy: updated,
            });
          }}
        />
        <Checkbox
          onChange={(e) => {
            // console.log(e.target.checked)
            setPatient({
              ...patient,
              referralBy: { ...patient.referralBy, checked: e.target.checked },
            });
          }}
        >
          I don’t have a referring doctor
        </Checkbox>
      </div>

      <CustomButton
        loading={isLoading}
        title={isEdit ? "Save" : "Next"}
        uiType="primary"
        onClick={async () => {
          // console.log(patient.referralBy)
          if (patient.referralBy && patient.referralBy.checked == true) {
            if (isEdit) {
              await editPatient();
              setIsModalClose(false);
              await getPatients();
              setisfirst(true)
              return;
            }
            var temp = patient;
            delete temp.referralBy;
            setPatient(temp);
            setStep(7);
            setisfirst(true)

          } else {
            if (
              (patient.referralBy && patient.referralBy.lastName == "") ||
              (patient.referralBy &&
                patient.referralBy.lastName == undefined) ||
              (patient.referralBy && patient.referralBy.firstName == "") ||
              (patient.referralBy && patient.referralBy.firstName == undefined)
            ) {
              Modal.error({
                title: "please fill all information.",
              });
              setisfirst(false)
              return;
            }
            if (
              patient.referralBy &&
              !EmailValidate(
                patient.referralBy && patient.referralBy.email
                  ? patient.referralBy.email
                  : ""
              )
            ) {
              Modal.error({
                title: "Email is invalid",
              });
              setisfirst(false)
              return;
            }

            if (isEdit) {
              if (patient.referralBy && patient.referralBy.firstName) {
                var res2 = await postData(
                  `admin/patient/${patient.id}/referral-by`,
                  patient.referralBy
                );
                if (res2.status != 201) {
                  Modal.error({
                    title: res2,
                  });
                }
              }
              setIsModalClose(false);
              await getPatients();
              setisfirst(true)
              return;
            }
setisfirst(true)
            setStep(7);
          }
        }}
      />
    </div>
  );
};

export default AddPatient;

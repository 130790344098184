import React from "react";
import {
  Table,
  Modal,
  Button,
  Form,
  Input,
  Select,
  Space,
  Tag,
  DatePicker,
  Radio,
} from "antd";
import { useMobile } from "../../hooks";
import { useState, useEffect, useRef } from "react";
import { fetchData, putData } from "../../endpoints";
import { ExclamationCircleFilled, FilterOutlined } from "@ant-design/icons";
import {
  SearchOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import AddComment from "./comment/addComment";
import access from "../../assets/access";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 10, span: 12 },
};

function Translators() {
  const [form] = Form.useForm();
  const isMobile = useMobile();
  const [approvemodal, setapprovemodal] = useState(false);
  const [rejectmodal, setrejectmodal] = useState(false);
  const [data_appointment, setdata_appointment] = useState([]);
  const [addnotemodal, setaddnotemodal] = useState(false);
  const [dataSource, setdataSource] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [isLoadingUpcoming, setisLoadingUpcoming] = useState(false);
  const [selectedservice, setselectedservice] = useState();
  const [dataSource2, setdataSource2] = useState([]);
  const [typeFilter, setTypeFilter] = useState("");
  const [selectedappointment, setselectedappointment] = useState({});
  const [isAppointment, setIsAppointment] = useState(false);
  const [editappointmentmodalopen, seteditappointmentmodalopen] =
    useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [SearchUserModal, setSearchUserModal] = useState(false);
  const [SearchappointmentModal, setSearchappointmentModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [patients, setPatients] = useState([]);
  const [config, setConfig] = useState([]);
  // const [typeFilter, settypeFilter] = useState("");
  const [selected, setSelected] = useState({});
  const searchInput = useRef(null);
  const getConfig = async () => {
    var res = await fetchData("user/config");
    if (res.status == 200) {
      console.log(res.data.data, "configggggg");
      setConfig(res.data.data);
    }
  };
  const getColumnSearchProps_upcoming = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <DatePicker.RangePicker
          style={{ marginBottom: 8, display: "block" }}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e ? [e] : [])}
          onPressEnter={() => {
            handleSearch_upcoming(selectedKeys, confirm, dataIndex);
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch_upcoming(selectedKeys, confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            type="primary"
            onClick={async () => {
              let date = new Date();

              let year = date.getFullYear();
              let month = date.getMonth() + 1;
              if (month < 10) month = "0" + month;
              let day = date.getDate();
              if (day < 10) day = "0" + day;
              setisLoadingUpcoming(true);

              var res = await fetchData(
                "admin/appointment-translator?deletedAt=false&includes[]=appointment&includes[]=transaction&perPage=1000"
              );
              if (res.status == 200) {
                setdataSource2(res.data.data);
              }
              setisLoadingUpcoming(false);
            }}
            // icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Today
          </Button>
          <Button
            onClick={() => clearFilters && handleReset_upcoming(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {}
            }
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const handleSearch_upcoming = async (selectedKeys, confirm, dataIndex) => {
    setisLoadingUpcoming(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length === 0) {
      setdataSource2([]);
      await getupcoming(1, true);
    } else {
      let year = selectedKeys[0][0].$y;
      let month = selectedKeys[0][0].$M + 1;
      let day = selectedKeys[0][0].$D;
      let year2 = selectedKeys[0][1].$y;
      let month2 = selectedKeys[0][1].$M + 1;
      let day2 = selectedKeys[0][1].$D;

      var res1 = await fetchData(
        `admin/appointment-translator?deletedAt=false&includes[]=appointment&${dataIndex}Gte=${year}-${month}-${day}&${dataIndex}Lte=${year2}-${month2}-${day2}&includes[]=transaction&perPage=1000`
      );
      console.log(res1);

      if (res1.status == 200) {
        res1.data.data.map((tempdata, index) => {
          tempdata["key"] = index;
        });
        setdataSource2(res1.data.data);
      }
    }
    setisLoadingUpcoming(false);
  };
  const handleReset_upcoming = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    setdataSource2([]);
    await getupcoming(1, true);
  };
  const checked = (u) =>
    Modal.confirm({
      title: "Are you sure you want to check this appointment?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        checkeWith(u);
      },
      onCancel() {},
    });
  const checkeWith = async (u) => {
    setisLoadingUpcoming(true);
    var date = new Date();
    var res = await putData(`admin/appointment/${u.id}`, {
      approvedAt: date,
    });
    if (res.status == 200) {
      Modal.success({
        title: "appointment checked successfully",
      });
    } else {
      Modal.error({
        title: res,
      });
    }
    await getupcoming(1, true);
    setisLoadingUpcoming(false);
  };

  const columns_upcoming = [
    {
      title: "Appointment ID",
      dataIndex: "appointment",
      key: "appointment",
      // width: "20%",
      render: (u) => <p>{u ? u.id : ""}</p>,
    },
    {
      title: (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div>Patient</div>{" "}
         
        </div>
      ),
      dataIndex: "appointment",
      key: "appointment",
      // width: "15%",
      render: (u) => (
        <a
          href={
            privilage.indexOf(access.patientManegment.showPatient) > -1 &&
            "/patients/" + u.patientId
          }
          style={{ color: "black" }}
        >
          {u.patientId}
        </a>
      ),
    },
    {
      title: "Type",
      dataIndex: "appointment",
      key: "appointment",
      // width: "20%",
      render: (u) => <div>{u.locationType} </div>,
    },
    {
      title: "Date",
      dataIndex: "appointment",
      key: "appointment",
      // width: "15%",
      // ...getColumnSearchProps_upcoming("startedAt"),
      render: (u) =>
        new Date(u.startedAt) > new Date() ? (
          <p>
            {new Date(u.startedAt)
              .toISOString()
              .replace(/^([^T]+)T([^\.]+)(.+)/, "$1 $2")
              .slice(0, -3)}
            <br />
            {parseInt(
              (new Date(u.startedAt) - new Date()) / (1000 * 60 * 60 * 24),
              10
            ) + "  "}
            Days left
          </p>
        ) : (
          <p>
            {new Date(u.startedAt)
              .toISOString()
              .replace(/^([^T]+)T([^\.]+)(.+)/, "$1 $2")
              .slice(0, -3)}
          </p>
        ),
    },
    {
      title: "Language",
      dataIndex: "lang",
      key: "lang",
      render: (u) => (u ? <div>{u}</div> : <div>-</div>),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (u) => (u ? <div style={u=="pending"?{color:"red"}:{}}>{camelize(u)}</div> : <div>-</div>),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (u) => (u ? <div>{u}</div> : <div>-</div>),
    },
    
  ];
  function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ?word.toUpperCase()  : word.toLowerCase();
    }).replace(/\s+/g, '');
  }
  async function getupcoming(page = 1, relod = false) {
    let date = new Date();

    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate() > 2 ? date.getDate() - 1 : 29;
    setisLoadingUpcoming(true);

    if (dataSource2.length < (page - 1) * 15 + 2 || relod) {
      var res = await fetchData(
        "admin/appointment-translator?deletedAt=false&includes[]=appointment&includes[]=transaction&perPage=15&page=" +
          // `admin/appointment?deletedAt=false&includes[]=translators&startedAtGte=${year}-${month}-${day}&sortKey=startedAt&includes[]=event&includes[]=approvedBy&includes[]=patient&sortValue=1&perPage=15&page=` +
          page
      );
      var temp = dataSource2;
      if (relod) {
        temp = [];
      }
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setdataSource2(
            temp
              .filter((a, i) => {
                return a.id;
              })
              .concat([...res.data.data])
          );
        } else {
          setdataSource2(
            temp
              .filter((a, i) => {
                return a.id;
              })
              .concat([...res.data.data, {}])
          );
        }
      } else {
        Modal.error({
          title: res,
        });
      }
    }
    setisLoadingUpcoming(false);
  }
  // async function fetchEvents(page = 1, isReload = false) {
  //   var temp = isReload ? [] : data;
  //   setisLoadingUpcoming(true);
  //   if (data.length < (page - 1) * 15 + 2 || isReload) {
  //     var res = await fetchData(
  //       "admin/appointment-event/?deletedAt=false&perPage=15&page=" + page
  //     );
  //     res.data.data.map((tempdata, index) => {
  //       tempdata["key"] = index;
  //     });
  //     if (res.status === 200) {
  //       if (res.data.data.length < 15) {
  //         setdata_appointment(
  //           temp
  //             .filter((a, i) => {
  //               return a.title;
  //             })
  //             .concat(res.data.data)
  //         );
  //       } else {
  //         setdata_appointment(
  //           temp
  //             .filter((a, i) => {
  //               return a.title;
  //             })
  //             .concat([
  //               ...res.data.data,
  //               { createdAt: new Date().toString(), locationTypes: [] },
  //             ])
  //         );
  //       }
  //     } else {
  //       Modal.error({
  //         title: res,
  //       });
  //     }
  //   }
  //   setisLoadingUpcoming(false);
  // }
  useEffect(() => {
    getConfig();
    if (privilage.indexOf(access.todoList.showappointment) > -1) {
      getupcoming();
    }
    // if (privilage.indexOf(access.todoList.indexPatient) > -1) {
    //   getpatientmodal();
    // }
    // if (privilage.indexOf(access.todoList.indexEvent) > -1) {
    //   fetchEvents();
    // }
  }, []);

  return (
    <>
      <div
        style={{
          minHeight: "150vh",
          minWidth: "100%",
          backgroundColor: "white",
        }}
      >
        {/* <div>Translators</div> */}
        {privilage.indexOf(access.todoList.showappointment) > -1 && (
          <div
            style={
              isMobile
                ? {
                    padding: "40px 40px",
                    textAlign: "center",
                    margin: "auto",
                    minHeight: "50%",
                  }
                : {
                    padding: "70px 70px",
                    textAlign: "center",
                    margin: "auto",
                    minHeight: "50%",
                  }
            }
          >
            <div
              style={
                isMobile
                  ? {
                      display: "flex",
                      justifyContent: "center",
                      padding: "40px 0px",
                      fontSize: "18px",
                    }
                  : {
                      display: "flex",
                      justifyContent: "start",
                      padding: "40px 0px",
                      fontSize: "24px",
                    }
              }
            >
              <strong>Translators</strong>
            </div>
            <Table
              scroll={{ x: "auto" }}
              size="small"
              columns={columns_upcoming}
              dataSource={dataSource2}
              loading={isLoadingUpcoming}
              pagination={{
                onChange: async (page) => {
                  await getupcoming(page);
                },

                pageSize: 15,
              }}
            />
          </div>
        )}
      </div>
      {/* <Modal
        loading={isLoading}
        title="Approve"
        open={approvemodal}
        okText="Approve"
        onCancel={() => setapprovemodal(false)}
        confirmLoading={isLoading}
        onOk={async () => {
          setisLoading(true);
          var res = await putData(
            `admin/service-purchase/${selectedservice.purchaseId}/step/${selectedservice.id}/approve`
          );
          if (res.status == 200) {
            setapprovemodal(false);
            await getneedapprove(1, true);
            setisLoading(false);
            Modal.success({
              title: "this step approved successfully",
            });
          } else {
            setisLoading(false);
            Modal.error({ title: res });
          }
        }}
      >
        <p>Are you sure to Approve this step?</p>
      </Modal> */}
      {/* <Modal
        title=""
        open={rejectmodal}
        // onOk={handleOk}
        footer={null}
        onCancel={() => setrejectmodal(false)}
        confirmLoading={isLoading}
      >
        <div style={{ textAlign: "center", fontSize: "36px" }}>
          <strong>Reject</strong>
        </div>
        <div style={{ textAlign: "center" }}>
          Please specify the reason for rejecting this step.
        </div>
        <hr />

        <Form
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={async (values) => {
            setisLoading(true);
            let temp = {
              subject: values.subject,
              message: values.message,
            };
            var res = await putData(
              `admin/service-purchase/${selectedservice.purchaseId}/step/${selectedservice.id}/decline`,
              temp
            );

            if (res.status == 200) {
              setrejectmodal(false);
              await getneedapprove(1, true);
              Modal.success({ title: "Step rejected successfully." });
            } else {
              Modal.error({ title: res });
            }
            form.resetFields();
            setisLoading(false);
          }}
          style={{ maxWidth: 600 }}
        >
          <Form.Item
            name="subject"
            label="Subject"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="message"
            label="Message"
            rules={[{ required: true }]}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal> */}
      {/* <Modal
        title=""
        open={addnotemodal}
        top={"0"}
        width={isMobile ? "90vw" : "65vw"}
        footer={null}
        onCancel={() => setaddnotemodal(false)}
        closeIcon={null}
      >
        <AddComment
          isOpen={addnotemodal}
          setIsOpen={setaddnotemodal}
          getComments={() => {}}
          patients={[
            {
              id: selected.patientId,
              firstName: selected.firstName,
              lastName: selected.lastName,
            },
          ]}
          purchase={null}
          defultComment={selected}
          selecting={true}
          setDefult={setSelected}
        />
      </Modal> */}
      {/* <Modal
        open={SearchUserModal}
        title="Search Patient"
        width={1000}
        onOk={async () => {
          await okPatientModal();
        }}
        onCancel={() => {
          setSearchUserModal(false);
          setSelectedRowKeys(false);
        }}
        style={isMobile ? {} : { maxHeight: "80vh" }}
      >
        <div
          style={
            isMobile
              ? { minWidth: "90%" }
              : { maxHeight: "75vh", overflowY: "scroll" }
          }
        >
          <Table
            scroll={{ x: "auto" }}
            loading={isLoading}
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            columns={columns2}
            dataSource={data}
            size="small"
            style={isMobile ? { margin: "0px" } : { margin: "40px" }}
            pagination={{
              onChange: async (page) => {
                await getpatientmodal(page);
              },
              // total: 50,
              pageSize: 15,
            }}
          />
        </div>
      </Modal> */}
      {/* <Modal
        style={{ maxHeight: "80vh" }}
        open={SearchappointmentModal}
        title="Search Appoitment Event"
        width={1000}
        onOk={async () => {
          await okAppintmentModal();
        }}
        onCancel={() => {
          setSearchappointmentModal(false);
          setSelectedRowKeys(false);
        }}
      >
        <div style={isMobile ? {} : { maxHeight: "75vh", overflowY: "scroll" }}>
          <Table
            scroll={{ x: "auto" }}
            loading={isLoading}
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            style={
              isMobile ? { minWidth: "80vw" } : { width: "90%", margin: "40px" }
            }
            columns={columns_appointment}
            dataSource={data_appointment}
            pagination={{
              onChange: async (page) => {
                await fetchEvents(page);
              },
              // total: 50,
              pageSize: 15,
            }}
          />
        </div>
      </Modal> */}
    </>
  );
}

export default Translators;

import React, { useState, useRef } from "react";
import { fetchData, putData, postData } from "../../endpoints";
import { useEffect } from "react";
import {
  Collapse,
  Form,
  Table,
  Button,
  Input,
  Space,
  Modal,
  Tag,
  Tooltip,
} from "antd";
import { useMobile } from "../../hooks";
import Highlighter from "react-highlight-words";
import {
  SearchOutlined,
  ExclamationCircleFilled,
  ReloadOutlined,
  CaretDownOutlined, 
  DownCircleOutlined,
  ToTopOutlined,
  UpCircleOutlined,
  CaretUpOutlined,
} from "@ant-design/icons";

import "./ReplyStyle.scss";
import access from "../../assets/access";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;
const { Panel } = Collapse;

const Todo_Reply = () => {
  const [datasource, setdataSource] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selected, setselected] = useState({});
  const [users, setUsers] = useState([]);
  const [reply, setReply] = useState(false);
  const [SearchUserModal, setSearchUserModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const searchInput = useRef(null);
  const [form] = Form.useForm();
  const [patients, setPatients] = useState([]);
  const [SearchPatient, setSearchPatient] = useState(false);
  const [isAppointment, setIsAppointment] = useState(false);
  const isMobile = useMobile();
  const getpatientmodal = async (page = 1, reload = false) => {
    setIsLoading(true);
    var temp = patients;
    if (reload) {
      temp = [];
    }
    if (patients.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        `admin/patient/?deletedAt=false&verifiedAt=true&perPage=15&page=` + page
      );
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setPatients(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat(res.data.data)
          );
        } else {
          setPatients(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat([
                ...res.data.data,
                { createdAt: new Date().toString(), serviceTypes: [] },
              ])
          );
        }
      }
    }

    setIsLoading(false);
  };
  const okPatientModal = async (id = null) => {
    setIsLoading(true);
    // console.log(data[selectedRowKeys].userId);
    await getReply(1, true, data[selectedRowKeys].userId);
    setSearchPatient(false);
    setIsLoading(false);
  };
  const data = patients.map((p, i) => {
    return {
      ...p,
      key: i,
      name: p.firstName + " " + p.lastName,
      createdAt: new Date(p.createdAt).toDateString(),
    };
  });

  const handleSearchUser = async (selectedKeys, confirm, dataIndex) => {
    setIsLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length === 0) {
      await getUsers();
    } else {
      let search = "";
      if (dataIndex == "name") search = "firstName";
      else if (dataIndex == "phone") search = "phoneNumber";
      else search = "email";
      var res = await fetchData(`admin/user/?${search}=` + selectedKeys[0]);
      if (res.status == 200) {
        setUsers(res.data.data);
      }
    }
    setIsLoading(false);
  };

  const handleResetUser = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    await getUsers(1, true);
  };
  const getColumnSearchPropsUser = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearchUser(selectedKeys, confirm, dataIndex)
          }
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchUser(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleResetUser(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const okUserModal = async (id = null) => {
    console.log(id);
    setIsLoading(true);
    var res;
    if (id) {
      res = await fetchData(
        `admin/purchase-reply?readAt=false&includes[]=sender&sender_type=client&includes[]=purchaseStep.patient&senderId=${id}`
      );
    } else
      res = await fetchData(
        `admin/purchase-reply?readAt=false&sender_type=client&includes[]=sender&includes[]=purchaseStep.patient`
      );
    setdataSource(res.data.data);
    setSearchUserModal(false);
    setSelectedRowKeys(false);
    setIsLoading(false);
  };
  const getUsers = async (page = 1, reload = false) => {
    setIsLoading(true);
    var temp = users;
    if (reload) {
      temp = [];
    }
    if (users.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        "admin/user/?deletedAt=false&perPage=15&page=" + page
      );
      res.data.data.map((tempdata, index) => {
        tempdata["key"] = index;
      });
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setUsers(
            temp
              .filter((a, i) => {
                return a.email;
              })
              .concat([...res.data.data])
          );
        } else {
          setUsers(
            temp
              .filter((a, i) => {
                return a.email;
              })
              .concat([...res.data.data, {}])
          );
        }
      } else {
        Modal.error({ title: res });
      }
    }
    setIsLoading(false);
  };
  const user_columns = [
    {
      title: "Name",
      dataIndex: "",
      key: "",
      width: "25%",
      onCell: (record) => {
        return {
          onClick: () => {
            okUserModal(record.id);
          },
        };
      },
      ...getColumnSearchPropsUser("name"),
      render: (record) => (
        <h2 className="name-table">
          {record.firstName + " " + record.lastName}
        </h2>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "35%",
      ...getColumnSearchPropsUser("email"),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      ...getColumnSearchPropsUser("phone"),
    },
  ];

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {}
            }
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const handleSearch = async (selectedKeys, confirm, dataIndex) => {
    setIsLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length === 0) {
      setdataSource([]);
      await getReply();
    } else {
      let search = "";
      console.log(dataIndex);
      if (dataIndex == "step") search = "purchaseStepId";
      if (dataIndex == "subject") search = "subject";

      var res = await fetchData(
        `admin/purchase-reply?readAt=false&sender_type=client&includes[]=sender&includes[]=purchaseStep.patient&${search}=` +
          selectedKeys[0]
      );

      if (res.status == 200) {
        res.data.data.map((temp) => {
          temp["name"] = temp.firstName + " " + temp.lastName;
        });

        setdataSource(res.data.data);
      }
    }
    setIsLoading(false);
  };
  const handleReset = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    setdataSource([]);
    await getReply(1, true);
  };
  const getColumnSearchPropsmodal = (dataIndex, isRemove = false) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            handleSearchmodal(selectedKeys, confirm, dataIndex);
          }}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchmodal(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleResetmodal(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const handleSearchmodal = async (selectedKeys, confirm, dataIndex) => {
    setIsLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length == 0) {
      await getpatientmodal();
    } else {
      let search = "";
      if (dataIndex == "name") search = "firstName";
      else search = "id";
      var res = await fetchData(
        `admin/patient/?verifiedAt=true&deletedAt=false&${search}=` +
          selectedKeys[0]
      );
      if (res.status == 200) {
        setPatients(
          res.data.data.map((temp) => {
            return { ...temp, name: temp.firstName + " " + temp.lastName };
          })
        );
      }
    }
    setIsLoading(false);
  };
  const handleResetmodal = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    setdataSource([]);
    await getpatientmodal(1, true);
  };
  const columns = [
    {
      title: (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div>Sender</div>{" "}
          <SearchOutlined
            style={{ fontSize: "12px" }}
            onClick={() => {
              setSearchUserModal(true);
            }}
          />
          <ReloadOutlined
            style={{ fontSize: "12px" }}
            onClick={() => {
              getReply(1, true);
            }}
          />
        </div>
      ),
      dataIndex: "sender",
      key: "sender",
      width: "18%",
      render: (u) =>
        u ? (
          <div className="d-flex flex-column gap-2">
            {/* <Tag
              style={
                isMobile
                  ? { maxWidth: "max-content", fontSize: "10px" }
                  : { maxWidth: "max-content" }
              }
              color={u.type == "admin" ? "blue" : "magenta"}
            >
              {u.type ?? "user"}
            </Tag> */}
            <a
              href={
                privilage.indexOf(access.userManegment.showUser) > -1 &&
                "/users/" + u.id
              }
              style={{ color: "black" }}
            >
              {u.firstName + " " + u.lastName}
            </a>
          </div>
        ) : (
          <div className="d-flex flex-column gap-2">
            <Tag
              style={
                isMobile
                  ? { maxWidth: "max-content", fontSize: "10px" }
                  : { maxWidth: "max-content" }
              }
              color="blue"
            >
              admin
            </Tag>
            <p>System Reply</p>
          </div>
        ),
    },

    {
      title: (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div>Patient</div>{" "}
          <SearchOutlined
            style={{ fontSize: "12px" }}
            onClick={() => {
              setIsAppointment(false);
              setSearchPatient(true);
            }}
          />
          <ReloadOutlined
            style={{ fontSize: "12px" }}
            onClick={() => {
              getReply(1, true);
            }}
          />
        </div>
      ),
      dataIndex: "purchaseStep",
      key: "purchaseStep",
      width: "20%",
      render: (u) =>
        u && u.patient ? (
          <a
            style={{ color: "black" }}
            href={
              privilage.indexOf(access.todoList.showPatient) > -1
                ? `/patients/${u.patient.id}`
                : null
            }
          >
            {u.patient.firstName + " " + u.patient.lastName}
          </a>
        ) : (
          <></>
        ),
    },
    {
      title: "Step",
      width: "10%",
      dataIndex: "purchaseStep",
      key: "purchaseStep",

      render: (u) =>
        u.id ? (
          <div>
            <a
              href={
                privilage.indexOf(access.todoList.showpurchase) > -1
                  ? `/patients/${u.patient.id}/services/in-progress/${u.purchaseId}/${u.id}`
                  : null
              }
              style={{ color: "black" }}
              target="_blank"
            >
              {u.id}
            </a>
          </div>
        ) : (
          <></>
        ),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "20%",
      render: (u) => (
        <p style={{ fontSize: "11px" }}>{new Date(u).toLocaleString()}</p>
      ),
    },
    
    Table.EXPAND_COLUMN,
    


    
     { title: "",
      dataIndex: "",
      key: "x",
      render: (u) => (
       <div className="d-flex flex-row"> {privilage.indexOf(access.todoList.addReply) > -1 && 
       <Button type='primary' size={isMobile ? "small" : "default"}   onClick={() => {
         setselected(u);
         setReply(true);
       }}>
       Reply
    </Button>}
   { privilage.indexOf(access.todoList.readReply) > -1 && 
     <Button    style={{ backgroundColor: "#389e0d" , color:'white',  }}
       size={isMobile ? "small" : "default"}onClick={() => {
         done(u);
       }}
     >
       Mark As Done
       </Button>
    }</div>
    
      )}
  ];

  const columns2 = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "25%",

      ...getColumnSearchPropsmodal("name"),
      render: (record) => (
        <h2 style={{ cursor: "pointer" }} className="name-table">
          {record}
        </h2>
      ),
    },
    {
      title: "MRN",
      dataIndex: "id",
      key: "id",
      render: (id, record) =>
        record.verifiedAt ? (
          <span>{id}</span>
        ) : (
          <span style={{ color: "rgba(46, 108, 223, 1)", cursor: "pointer" }}>
            Not Yet Verified
          </span>
        ),
      ...getColumnSearchPropsmodal("id"),
    },
  ];
  async function getReply(page = 1, reload = false, userId = null) {
    setIsLoading(true);

    if (datasource.length < (page - 1) * 15 + 2 || reload) {
      if (userId) {
        var res = await fetchData(
          `admin/purchase-reply?readAt=false&includes[]=sender&includes[]=purchaseStep.patient&senderId=${userId}`
        );
      } else {
        var res = await fetchData(
          "admin/purchase-reply?readAt=false&sender_type=client&includes[]=sender&includes[]=purchaseStep.patient&perPage=15&page=" +
            page
        );
      }
      res.data.data = res.data.data.map((d, i) => {
        return { ...d, key: i };
      });
      var temp1 = datasource;
      if (reload) {
        temp1 = [];
      }
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setdataSource(
            temp1
              .filter((a, i) => {
                return a.id;
              })
              .concat([...res.data.data])
          );
        } else {
          setdataSource(
            temp1
              .filter((a, i) => {
                return a.id;
              })
              .concat([...res.data.data, {}])
          );
        }
      } else {
        Modal.error({
          title: res,
        });
      }
      //
    }
    setIsLoading(false);
  }
  useEffect(() => {
    getReply();
    if (privilage.indexOf(access.todoList.indexUser) > -1) getUsers();
    if (privilage.indexOf(access.todoList.indexPatient) > -1) getpatientmodal();
  }, []);
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
      // console.log(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  const done = (u) =>
    Modal.confirm({
      title: "Are you sure you want to complete this purchase?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        doneWith(u);
      },
      onCancel() {},
    });
  const doneWith = async (u) => {
    setIsLoading(true);
    var res = await putData(
      `admin/service-purchase/${u.purchaseStep.purchaseId}/step/${u.purchaseStepId}/reply/${u.id}/read`
    );
    if (res.status != 200) {
      Modal.error({ title: res });
    }
    await getReply(1, true);
    setIsLoading(false);
  };

  return (
    <>
      <div
        style={{
          minHeight: "150vh",
          minWidth: "100%",
          backgroundColor: "white",
        }}
      >
        <div
          style={
            isMobile
              ? {
                  padding: "0px 20px",
                  // textAlign: "center",
                  margin: "auto",
                  minHeight: "50%",
                }
              : {
                  padding: "70px 70px",
                  textAlign: "center",
                  margin: "auto",
                  minHeight: "50%",
                }
          }
        >
          <div
            style={
              isMobile
                ? {
                    padding: "40px 0px 20px 0px",
                    fontSize: "18px",
                  }
                : {
                    display: "flex",
                    justifyContent: "start",
                    padding: "40px 0px",
                    fontSize: "24px",
                  }
            }
          >
            <strong>Service Step Replies</strong>
          </div>
          <div style={isMobile ? { minWidth: "80vw" } : {}}>
            <Table
              scroll={{ x: "auto" }}
              loading={isloading}
              columns={columns}
              dataSource={datasource}
              expandIcon={({expanded, onExpand, record})=> {return expanded?<CaretUpOutlined  style={{fontSize:'18px'}}            onClick={(e) => onExpand(record, e)}
              />:<CaretDownOutlined  style={{fontSize:'18px'}}            onClick={(e) => onExpand(record, e)}
              />}}
              expandable={{
                expandedRowRender: (record) => (
                  <p
                    style={{
                      margin: 0,
                    }}
                  >
                    {record.message}
                  </p>
                ),
              }}
              pagination={{
                onChange: async (page) => {
                  await getReply(page);
                },
                pageSize: 15,
              }}
            />
          </div>
        </div>
      </div>
      <Modal
        title=""
        open={reply}
        // okText="Send"
        footer={null}
        onCancel={() => {
          setReply(false);
          form.resetFields();
          setselected({});
        }}
      >
        <strong
          style={{
            textAlign: "center",
            margin: "auto",
            display: "flex",
            marginBottom: "20px",
            justifyContent: "center",
            fontSize: "24px",
          }}
        >
          Send Reply
        </strong>

        <Form
          className="px-3"
          labelCol={{ span: 5 }}
          form={form}
          // layout="vertical"
          onFinish={async (values) => {
            setIsLoading(true);
            var temp = { subject: values.subject, message: values.message };
            var res = await postData(
              `admin/service-purchase/${selected.purchaseStep.purchaseId}/step/${selected.purchaseStepId}/reply`,
              temp
            );
            if (res.status == 201) {
              setReply(false);
              Modal.success({ title: "Reply send successfully." });
              form.resetFields();
              await getReply(1, true);
            } else {
              Modal.error({ title: res });
            }
            setIsLoading(false);
          }}
        >
          <Form.Item
            rules={[{ required: true }]}
            label="Subject"
            name="subject"
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            label="Message"
            name="message"
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isloading}>
              Submit
            </Button>
            <Button
              type="default"
              onClick={() => {
                setReply(false);
                form.resetFields();
                setselected({});
              }}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        style={{ maxHeight: "78vh" }}
        open={SearchUserModal}
        title="Search User"
        width={1000}
        onOk={() => {
          okUserModal(users[selectedRowKeys[0]].id);
          // okUserModal(2);
        }}
        onCancel={() => {
          setSearchUserModal(false);
        }}
      >
        <div
          style={
            isMobile
              ? { minWidth: "80vw" }
              : { maxHeight: "75vh", overflowY: "scroll" }
          }
        >
          <Table
            scroll={{ x: "auto" }}
            loading={isloading}
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            columns={user_columns}
            dataSource={users}
            size="small"
            style={isMobile ? {} : { width: "90%", margin: "40px" }}
            pagination={{
              onChange: async (page) => {
                await getUsers(page);
              },
              // total: 50,
              pageSize: 15,
            }}
          />
        </div>
      </Modal>
      <Modal
        open={SearchPatient}
        title="Search Patient"
        width={1000}
        onOk={async () => {
          await okPatientModal();
        }}
        onCancel={() => {
          setSearchPatient(false);
          setSelectedRowKeys(false);
        }}
        style={isMobile ? {} : { maxHeight: "80vh" }}
      >
        <div
          style={
            isMobile
              ? { minWidth: "90%" }
              : { maxHeight: "75vh", overflowY: "scroll" }
          }
        >
          <Table
            scroll={{ x: "auto" }}
            loading={isloading}
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            columns={columns2}
            dataSource={data}
            size="small"
            style={isMobile ? { margin: "0px" } : { margin: "40px" }}
            pagination={{
              onChange: async (page) => {
                await getpatientmodal(page);
              },
              // total: 50,
              pageSize: 15,
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default Todo_Reply;
